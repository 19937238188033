import React, { useState, useEffect } from "react";
import {
    Button,
    message,
    Spin,
    InputNumber,
    Input,
    Popconfirm,
    DatePicker,
    Modal,
    Table as AntTable,
    Select,
    Table,
} from "antd";
import { CloseCircleOutlined, DeleteFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import api from "../../services/Api";

const DiscountCodes = () => {
    const [discountCodes, setDiscountCodes] = useState([]);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDiscountCodeId, setSelectedDiscountCodeId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await fetchDiscountCodes();
            await fetchUsers();
            setLoading(false);
        };
        fetchData();
    }, []);

    const fetchDiscountCodes = async () => {
        try {
            const response = await api.getDiscountCodes();
            const parsedDiscountCodes = response.map((code) => ({
                ...code,
                validFrom: dayjs(code.validFrom),
                validTo: dayjs(code.validTo),
            }));
            setDiscountCodes(parsedDiscountCodes);
        } catch (error) {
            message.error("Virhe haettaessa alennuskoodeja");
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await api.getUsers();
            setUsers(response);
            setFilteredUsers(response);
        } catch (error) {
            message.error("Virhe haettaessa käyttäjiä");
        }
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            const formattedDiscountCodes = discountCodes.map((code) => ({
                ...code,
                validFrom: code.validFrom.toISOString(),
                validTo: code.validTo.toISOString(),
            }));
            await api.saveDiscountCodes(formattedDiscountCodes);
            message.success("Alennuskoodit tallennettu");
        } catch (error) {
            message.error("Virhe tallennettaessa alennuskoodeja");
        } finally {
            setSaving(false);
        }
    };

    const handleInputChange = (key, property, value) => {
        setDiscountCodes((prevDiscountCodes) =>
            prevDiscountCodes.map((discountCode) =>
                discountCode.id === key ? { ...discountCode, [property]: value } : discountCode
            )
        );
    };

    const handleRemoveDiscountCode = async (key) => {
        try {
            await api.deleteDiscountCode(key);
            setDiscountCodes((prevDiscountCodes) =>
                prevDiscountCodes.filter((discountCode) => discountCode.id !== key)
            );
            message.success("Alennuskoodi poistettu");
        } catch (error) {
            message.error("Virhe poistettaessa alennuskoodia");
        }
    };

    const openUserModal = (discountCodeId) => {
        setSelectedDiscountCodeId(discountCodeId);
        setIsModalOpen(true);
    };

    const handleUserSelect = (userId) => {
        handleInputChange(selectedDiscountCodeId, "user_id", userId);
        setIsModalOpen(false);
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = users.filter((user) =>
            `${user.lastname} ${user.firstname} ${user.email}`.toLowerCase().includes(value)
        );
        setFilteredUsers(filtered);
    };

    const columns = [
        {
            title: "Koodi",
            dataIndex: "code",
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleInputChange(record.id, "code", e.target.value)}
                    className="w-32"
                />
            ),
        },
        {
            title: "Tyyppi",
            dataIndex: "type",
            render: (text, record) => (
                <Select value={text} onChange={(value) => handleInputChange(record.id, "type", value)} className="w-32">
                    <Select.Option value="static">Pysyvä</Select.Option>
                    <Select.Option value="single">Kertakäyttö</Select.Option>
                </Select>
            ),
        },
        {
            title: "Alennus",
            dataIndex: "discount",
            render: (text, record) => (
                <InputNumber
                    value={text}
                    onChange={(value) => handleInputChange(record.id, "discount", value)}
                    addonAfter="%"
                    className="w-24"
                />
            ),
        },
        {
            title: "Voimassa alkaen",
            dataIndex: "validFrom",
            render: (text, record) => (
                <DatePicker
                    value={record.validFrom}
                    onChange={(date) => handleInputChange(record.id, "validFrom", date)}
                />
            ),
        },
        {
            title: "Voimassa asti",
            dataIndex: "validTo",
            render: (text, record) => (
                <DatePicker value={record.validTo} onChange={(date) => handleInputChange(record.id, "validTo", date)} />
            ),
        },
        {
            title: "Käyttäjä",
            dataIndex: "userId",
            render: (text, record) => {
                const user = users.find((user) => user.id === record.user_id);

                return (
                    <>
                        {user ? (
                            <span className="flex items-center">
                                {user.email}
                                <CloseCircleOutlined
                                    onClick={() => handleInputChange(record.id, "user_id", null)}
                                    className="text-red-600 ml-1"
                                />
                            </span>
                        ) : (
                            <Button onClick={() => openUserModal(record.id)}>Valitse käyttäjä</Button>
                        )}
                    </>
                );
            },
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_, record) => (
                <Popconfirm
                    title="Haluatko varmasti poistaa alennuskoodin?"
                    onConfirm={() => handleRemoveDiscountCode(record.id)}
                    okText="Kyllä"
                    cancelText="Peruuta"
                >
                    <Button type="danger" icon={<DeleteFilled className="text-red-600" />} />
                </Popconfirm>
            ),
        },
    ];

    const userColumns = [
        {
            title: "Käyttäjä",
            dataIndex: "name",
            render: (_, record) => `${record.lastname}, ${record.firstname} - ${record.email}`,
        },
        {
            title: "Toiminto",
            render: (_, record) => (
                <Button type="primary" onClick={() => handleUserSelect(record.id)}>
                    Valitse
                </Button>
            ),
        },
    ];

    return (
        <Spin spinning={loading}>
            <h1>Alennuskoodit</h1>
            
            <Table
                columns={columns}
                dataSource={discountCodes}
                rowKey="id"
                pagination={false}
                rowClassName="bg-gray-100 m-1"
                rowHoverable={false}
                size="small"
            />

            <div className="flex justify-between mt-5">
                <Button type="dashed" onClick={() => setDiscountCodes([...discountCodes, { id: Date.now() }])}>
                    Lisää alennuskoodi
                </Button>
                <Button type="primary" onClick={handleSave} loading={saving}>
                    Tallenna
                </Button>
            </div>

            {/* Modal for selecting users */}
            <Modal title="Valitse käyttäjä" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}>
                <Input placeholder="Hae käyttäjiä" value={searchTerm} onChange={handleSearch} className="mb-2" />
                <AntTable columns={userColumns} dataSource={filteredUsers} rowKey="id" pagination={false} />
            </Modal>
        </Spin>
    );
};

export default DiscountCodes;
