
// PropertyForm.js
import React, { useContext, useEffect, useState } from "react";
import { Form, Button } from "antd";
import { AuthContext } from "../../context/AuthContext";
import PropertyFormBasicInfo from "./PropertyFormBasicInfo";
import PropertyFormImages from "./PropertyFormImages";
import PropertyApartmentList from "./PropertyApartmentList";
import FileUpload from "./FileUpload";

const PropertyForm = ({
    initialValues,
    onFinish,
    loading,
    isUserProperty = false,
}) => {
    const [description, setDescription] = useState(initialValues?.description || "");
    const [files, setFiles] = useState(initialValues?.files || []);
    const [images, setImages] = useState(initialValues?.images || []);
    const { user, hasRole } = useContext(AuthContext);
    const [modifiedInitialValues, setModifiedInitialValues] = useState(initialValues);

    useEffect(() => {
        if (!initialValues && isUserProperty) {
            setModifiedInitialValues((prevValues) => ({
                ...prevValues,
                ownershipType: "other",
            }));
        }
    }, [initialValues, isUserProperty]);

    useEffect(() => {
        if (initialValues) {
            setDescription(initialValues.description || "");
            setFiles(initialValues.files || []);
            setImages(initialValues.images || []);
        }
    }, [initialValues]);

    const handleSubmit = (values) => {
        onFinish({ ...values, description, images: images, files: files, userProperty: isUserProperty });
    };

    const fieldsDisabled =
        !initialValues || initialValues?.userProperty || hasRole(user.roles, "ROLE_SUPERADMIN") ? false : true;

    const handleFilesChange = (updatedFiles) => {
        setFiles(updatedFiles);
    };

    return (
        <Form layout="vertical" initialValues={modifiedInitialValues} onFinish={handleSubmit}>
            <PropertyFormBasicInfo
                initialValues={initialValues}
                fieldsDisabled={fieldsDisabled}
                isUserProperty={isUserProperty}
                description={description}
                setDescription={setDescription}
            />
            {!isUserProperty && (
                <PropertyFormImages
                    images={images}
                    setImages={setImages}
                    initialValues={initialValues}
                />
            )}
            {initialValues && (
                <div className="mt-5">
                    <h3 className="text-lg font-bold">Huoneistot</h3>
                    <PropertyApartmentList propertyId={initialValues?.id} />
                </div>
            )}

            <div className="mt-5">
                <FileUpload
                    initialFiles={initialValues?.files}
                    onFilesChange={handleFilesChange}
                    propertyId={initialValues?.id}
                />
            </div>

            <Form.Item className="text-right flex justify-end mt-5">
                <Button type="primary" htmlType="submit" loading={loading}>
                    Tallenna
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PropertyForm;
