import React, { useState, useEffect, forwardRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, addDays } from "date-fns";
import fi from "date-fns/locale/fi";

registerLocale("fi", fi);

const CustomWeekPicker = ({ initialStartDate, onDateChange, disabled }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [hoveredDate, setHoveredDate] = useState(null);
    const [formattedDate, setFormattedDate] = useState("");

    useEffect(() => {
        if (initialStartDate) {
            handleDateChange(initialStartDate);
        }
    }, []);

    const handleDateChange = (date) => {
        const start = date;
        const end = addDays(date, 7);
        const formatted = `${format(start, "dd.MM.yyyy")} - ${format(end, "dd.MM.yyyy")}`;

        setFormattedDate(formatted);
        setSelectedDate(date);

        // Call onDateChange after setting the states
        if (onDateChange) {
            onDateChange(format(start, "yyyy-MM-dd"));
        }
    };

    const handleDayMouseEnter = (date) => {
        setHoveredDate(date);
    };

    const handleDayMouseLeave = () => {
        setHoveredDate(null);
    };

    const renderDayContents = (day, date) => {
        const isHovered = hoveredDate && date >= hoveredDate && date <= addDays(hoveredDate, 7);
        return (
            <div
                className={`rounded ${isHovered ? "bg-blue-500 text-white" : ""}`}
                onMouseEnter={() => handleDayMouseEnter(date)}
                onMouseLeave={handleDayMouseLeave}
            >
                {day}
            </div>
        );
    };

    const CustomInput = forwardRef(({ onClick }, ref) => (
        <input
            type="text"
            value={formattedDate}
            onClick={onClick}
            ref={ref}
            className="border border-gray-300 rounded text-base cursor-pointer w-full"
            placeholder="Valitse päivämäärä"
            style={{
                padding: "3px 20px 3px 10px",
            }}
            readOnly
            disabled={disabled}
        />
    ));

    return (
        <DatePicker
            showIcon
            icon={
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    />
                </svg>
            }
            calendarIconClassName="text-gray-300 absolute right-0 top-0 text-lg"
            selected={selectedDate}
            onChange={handleDateChange}
            renderDayContents={renderDayContents}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                <div className="flex items-center justify-between px-2 bg-gray-200 rounded-t-lg">
                    <div
                        onClick={decreaseMonth}
                        className="p-2 rounded-lg hover:bg-gray-300 focus:outline-none cursor-pointer"
                    >
                        {"<"}
                    </div>
                    <span className="text-sm font-bold">
                        {format(date, "MMMM", { locale: fi })
                            .substring(0, format(date, "MMMM", { locale: fi }).length - 2)
                            .toUpperCase()}{" "}
                        {format(date, "yyyy")}
                    </span>
                    <div
                        onClick={increaseMonth}
                        className="p-2 rounded-lg hover:bg-gray-300 focus:outline-none cursor-pointer"
                    >
                        {">"}
                    </div>
                </div>
            )}
            weekDayClassName={() => "text-sm text-gray-600 capitalize"}
            calendarClassName="bg-white shadow-md rounded p-2"
            customInput={<CustomInput formattedDate={formattedDate} />}
            wrapperClassName="w-full"
            locale="fi"
            dateFormat="dd.MM.yyyy"
            showWeekNumbers
            disabled={disabled}
        />
    );
};

export default CustomWeekPicker;
