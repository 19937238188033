import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Spin } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import api from "../../services/Api";

const PaymentFeatureReturn = () => {
    const { result } = useParams();
    const [loading, setLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [auction, setAuction] = useState(null);

    const params = new URLSearchParams(window.location.search);

    if (!params.has("checkout-transaction-id")) {
        document.location.href = "/";
    }

    const confirmPayment = async () => {
        setLoading(true);

        try {
            const response = await api.confirmPayment(params.get("checkout-transaction-id"));

            setPaymentStatus(response.paytrail.status);
            setAuction(response.auction);
        } catch (error) {
            console.error("Error during payment processing: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        confirmPayment();
    }, []);

    return (
        <div className="flex flex-col justify-center w-full items-center">
            {loading ? (
                <div className="text-3xl w-full flex justify-center flex-col items-center">
                    <Spin className="mr-2 mb-5" size="large" />
                    <div>Vahvistetaan maksua...</div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center">
                    {paymentStatus === "ok" ? (
                        <>
                            <CheckCircleOutlined className="text-7xl mb-10 text-green-600" />

                            <h2 className="text-2xl">Maksu onnistui</h2>
                            <p className="text-xl">Kiitos maksusta! Ilmoituksesi on nyt julkaistu.</p>
                            <p className="text-xl underline text-blue-700">
                                <Link to={`/kohde/` + auction.number}>Katso ilmoituksesi täällä</Link>
                            </p>
                        </>
                    ) : (
                        <>
                            <CloseCircleOutlined className="text-7xl mb-10 text-red-600" />

                            <h2 className="text-2xl">Maksu epäonnistui</h2>
                            <p className="text-xl">
                                Yritä uudelleen tai ota yhteyttä <Link to={`/yhteystiedot`} className="text-xl underline text-blue-700">asiakaspalveluun</Link>.
                            </p>
                            <p>
                                <Link to={`/oma-sivu/ilmoitukset/` + auction.uuid} className="text-xl underline text-blue-700">
                                    Siirry takaisin ilmoituksen muokkaukseen
                                </Link>
                            </p>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default PaymentFeatureReturn;
