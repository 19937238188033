import React, { useState, useEffect } from "react";
import { Modal, Spin, Pagination, Input, Select } from "antd";
import api from "../../services/Api";
import { CameraOutlined } from "@ant-design/icons";

const PropertySelectModal = ({ open, onClose, onSelectProperty, selectedArea, onAreaChange }) => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(8); // 4 columns x 2 rows = 8 items per page
    const [totalProperties, setTotalProperties] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        if (open) {
            fetchProperties(currentPage, pageSize, searchTerm, selectedArea);
            fetchAreas();
        }
    }, [open, currentPage, pageSize, searchTerm, selectedArea]);

    const fetchProperties = async (page, pageSize, searchTerm, selectedArea) => {
        setLoading(true);
        try {
            const response = await api.getUserProperties(page, pageSize, searchTerm, selectedArea);
            setProperties(response.properties);
            setTotalProperties(response.total);
        } catch (error) {
            console.error("Error fetching properties:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAreas = async () => {
        try {
            const response = await api.getAvailableAreas(); // Assuming there's an API endpoint to fetch areas
            setAreas(response);
        } catch (error) {
            console.error("Error fetching areas:", error);
        }
    };

    const handlePropertyClick = (property) => {
        onSelectProperty(property);
        onClose();
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleAreaChange = (value) => {
        setCurrentPage(1);
        onAreaChange(value); // Ensure this method is passed from the parent component to update selectedArea
    };

    return (
        <Modal title="Valitse kohde" open={open} onCancel={onClose} footer={null} width={800}>
            <div className="flex">
                <Select placeholder="Valitse alue" onChange={handleAreaChange} value={selectedArea} className="w-1/4 mr-5">
                    <Select.Option value="">Kaikki paikat</Select.Option>
                    {areas?.map((area) => (
                        <Select.Option key={area} value={area}>
                            {area}
                        </Select.Option>
                    ))}
                </Select>

                <Input.Search
                    placeholder="Hae kohdetta"
                    onChange={handleSearchChange}
                    value={searchTerm}
                    style={{ marginBottom: "20px" }}
                />
            </div>

            {loading ? (
                <Spin />
            ) : (
                <>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        {properties.map((property) => (
                            <div
                                key={property.id}
                                className="border p-3 cursor-pointer"
                                onClick={() => handlePropertyClick(property)}
                            >
                                {property.images && property.images[0] ? (
                                    <img
                                        src={property.images[0].urls.thumbnail}
                                        alt={property.title}
                                        className="w-full h-32 object-cover rounded"
                                    />
                                ) : (
                                    <div
                                        className="bg-gray-50 flex justify-center items-center"
                                        style={{ width: "100%", height: "120px" }}
                                    >
                                        <CameraOutlined className="text-gray-400" style={{ fontSize: "40px" }} />
                                    </div>
                                )}
                                <h4 className="mt-2 font-bold">{property.title}</h4>
                                <p>
                                    {property.address}, {property.city}
                                </p>
                            </div>
                        ))}
                    </div>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalProperties}
                        onChange={handlePageChange}
                        style={{ marginTop: "20px", textAlign: "center" }}
                    />
                </>
            )}
        </Modal>
    );
};

export default PropertySelectModal;
