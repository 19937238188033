import React from "react";
import { Modal } from "antd";
import ProfileForm from "../forms/ProfileForm";

const ProfileEditModal = ({ user, open, onClose }) => {
    return (
        <Modal
            title="Muokkaa profiilia"
            open={open}
            width={800}
            footer={null}
            onCancel={onClose}
        >
            {/* You can add your form or content here */}
            <ProfileForm initialValues={user} onSaveSuccess={onClose} />
        </Modal>
    );
};

export default ProfileEditModal;