import React, { useState, useEffect } from "react";
import { Table, Button, Input, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import api from "../../services/Api";
import { CameraOutlined } from "@ant-design/icons";

const Properties = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalProperties, setTotalProperties] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        fetchProperties(currentPage, pageSize, searchTerm);
    }, [currentPage, pageSize, searchTerm]);

    const fetchProperties = async (page, pageSize, searchTerm) => {
        setLoading(true);
        try {
            const response = await api.getUserProperties(page, pageSize, searchTerm);
            setProperties(response.properties);
            setTotalProperties(response.total);
        } catch (error) {
            console.error("Error fetching properties:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddProperty = () => {
        navigate("/hallinta/kohde/uusi");
    };

    const handleEditProperty = (property) => {
        navigate(`/hallinta/kohde/muokkaa/${property.id}`);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on new search
    };

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const columns = [
        {
            width: 100,
            render: (text, record) => (
                <>
                    {record.images && record.images.length > 0 ? (
                        <img
                            src={record.images[0]?.urls.thumbnail}
                            alt={record.title}
                            style={{ width: "100px", height: "50px", objectFit: "cover" }}
                        />
                    ) : (
                        <div className="flex items-center justify-center">
                            <CameraOutlined className=" text-gray-500" />
                        </div>
                    )}
                </>
            ),
        },
        {
            title: "Kohde",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Paikka",
            dataIndex: "area",
            key: "area",
        },
        {
            title: "Osoite",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Postinumero",
            dataIndex: "zipcode",
            key: "zipcode",
        },
        {
            title: "Kaupunki",
            dataIndex: "city",
            key: "city",
        },
        {
            title: "Huoneistoja",
            dataIndex: "apartmentCount",
            key: "apartmentCount",
            render: (apartmentCount) => apartmentCount || '',
        },
        {
            title: "",
            key: "action",
            className: "text-right",
            render: (text, record) => (
                <Button type="primary" onClick={() => handleEditProperty(record)}>
                    Muokkaa
                </Button>
            ),
        },
    ];

    return (
        <div>
            <h1>Kohteet</h1>
            <div className="flex justify-between items-center mb-5">
                <Button type="primary" onClick={handleAddProperty}>
                    Lisää kohde
                </Button>
                <Input.Search
                    placeholder="Etsi kohteita"
                    onChange={handleSearchChange}
                    value={searchTerm}
                    style={{ width: 300 }}
                />
            </div>
            <>
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalProperties}
                    onChange={handlePageChange}
                    style={{ marginBottom: "20px", textAlign: "right" }}
                />
                <Table dataSource={properties} columns={columns} rowKey="id" pagination={false} loading={loading} />
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalProperties}
                    onChange={handlePageChange}
                    style={{ marginTop: "20px", textAlign: "right" }}
                />
            </>
        </div>
    );
};

export default Properties;
