import React, { useState } from "react";
import PropertyForm from "../forms/PropertyForm";
import api from "../../services/Api";
import { message, Modal } from "antd";

const PropertyFormModal = ({ open, onClose, property, onSelectProperty }) => {
    const [saving, setSaving] = useState(false);
    const [galleryModalOpen, setGalleryModalOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
 
    const handleSaveProperty = async (values) => {
        setSaving(true);

        try {
            const updatedProperty = {
                ...values,
                images: selectedImages,
            };

            const response = await api.saveUserProperty(property ? property.id : 0, updatedProperty);
            message.success("Kohde tallennettiin onnistuneesti");

            handleSelectProperty(response.id);
            onClose();
        } catch (error) {
            //console.error("Virhe tallennettaessa kohdetta:", error);
            message.error("Kohteen tallentaminen epäonnistui");
        } finally {
            setSaving(false);
        }
    };

    const handleSelectProperty = async (property) => {
        try {
            const response = await api.getUserProperty(property);
            onSelectProperty(response);
            setSelectedImages(response.images || []);
            onClose();
        } catch (error) {
            message.error("Kohteen tietojen hakeminen epäonnistui");
        }
    };

    const handleImageSelection = (images) => {
        //console.log("Selected images:", images);
        setSelectedImages(images);
    };

    return (
        <Modal title="Kohteen tiedot" open={open} onCancel={onClose} footer={null} width={1000}>
            <PropertyForm
                initialValues={property}
                onFinish={handleSaveProperty}
                loading={saving}
                galleryModalOpen={galleryModalOpen}
                setGalleryModalOpen={setGalleryModalOpen}
                selectedImages={selectedImages}
                handleImageSelection={handleImageSelection}
                isUserProperty={true}
            />
        </Modal>
    );
};

export default PropertyFormModal;
