import React from "react";

const ImageSelectThumbnail = ({ image, selected, onClick }) => {
    return (
        <div
            className={`relative cursor-pointer border-4 ${
                selected ? "border-blue-600 " : "border-transparent brightness-75 hover:border-gray-400 hover:brightness-100"
            }`}
            onClick={onClick}
        >
            <img
                src={image.urls.thumbnail}
                alt={image.altText || "Property Image"}
                className="w-full h-full object-cover"
            />
        </div>
    );
};

export default ImageSelectThumbnail;
