import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
    ClassicEditor,
    Heading,
    Bold,
    Italic,
    Link,
    List,
    BlockQuote,
    Undo,
    RemoveFormat,
    Table,
    ShiftEnter,
} from "ckeditor5";
import translations from "ckeditor5/translations/fi.js";
import "ckeditor5/ckeditor5.css";

const CKEditorWrapper = ({ content, setContent, disabled, height = '500px' }) => {
    return (
        <div className="prose" style={{ maxWidth: "100%" }}>
            <CKEditor
                editor={ClassicEditor}
                data={content}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                }}
                disabled={disabled}
                onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                        writer.setStyle("height", height, editor.editing.view.document.getRoot());
                    });
                }}
                config={{
                    language: "fi",
                    translations: [translations],
                    plugins: [
                        RemoveFormat,
                        Heading,
                        Bold,
                        Italic,
                        Link,
                        BlockQuote,
                        Undo,
                        List,
                        Table,
                        ShiftEnter,
                        // BulletedList,
                        // NumberedList,
                        // InsertTable,
                        // TableColumn,
                        // TableRow,
                        // MergeTableCells,
                        // Redo,
                    ],
                    toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "|",
                        "bulletedList",
                        "numberedList",
                        //"blockQuote",
                        "|",
                        "insertTable",
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "removeFormat",
                    ],
                    heading: {
                        options: [
                            {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "",
                            },
                            {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "font-bold text-2xl",
                            },
                            {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "font-bold text-xl",
                            },
                            {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "font-bold text-lg",
                            },
                            {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                            },
                            {
                                model: "heading5",
                                view: "h5",
                                title: "Heading 5",
                                class: "ck-heading_heading5",
                            },
                            {
                                model: "heading6",
                                view: "h6",
                                title: "Heading 6",
                                class: "ck-heading_heading6",
                            },
                        ],
                    },
                }}
            />
        </div>
    );
};

export default CKEditorWrapper;
