import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [initialTab, setInitialTab] = useState('login'); // Add initial tab state

    const showLoginModal = (tab = 'login') => { // Accept tab parameter
        setInitialTab(tab); // Set the initial tab state
        setLoginModalOpen(true);
    };
    const hideLoginModal = () => setLoginModalOpen(false);

    return (
        <ModalContext.Provider value={{ isLoginModalOpen, showLoginModal, hideLoginModal, initialTab }}>
            {children}
        </ModalContext.Provider>
    );
};
