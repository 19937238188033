import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import AuctionSearchBar from "./AuctionSearchBar";

import backgroundImage from "../../assets/images/lomahuuto-hero-2.webp";
import { AuthContext } from "../../context/AuthContext";

const Hero = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    const heroStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % promoTexts.length);
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const promoTexts = [
        "Valmiit ilmoituspohjat kaikille Suomen lomaosakeyhtiöille – Helppo ja nopea tapa luoda ammattimaiset ilmoitukset",
        "Automaattiset kauppakirjat ja vuokrasopimukset – Turvallinen ja vaivaton kaupankäynti",
        "Ilmoitusten tehokas mainostaminen eri kanavissa – Tavoittaa potentiaaliset ostajat ja vuokraajat nopeasti",
    ];

    return (
        <div style={heroStyle}>
            <Header pageType={0} />
            <div className="flex flex-col max-w-screen-2xl m-auto items-center">
                <div className="z-10 bg-transparent mt-20 md:mt-20 p-5 md:p-0 flex flex-col md:block text-center md:text-center w-full">
                    <h1 className="text-xl font-medium md:text-3xl text-primary md:font-light mb-5">
                        Myy, vuokraa tai huutokauppaa lomaosakkeesi tänään!
                    </h1>
                    <div className="mb-5 overflow-hidden md:flex h-12 hidden items-center justify-center relative">
                        <div key={currentTextIndex} className="promo-text">
                            {promoTexts[currentTextIndex]}
                        </div>
                    </div>
                    <div className="md:hidden block mb-5">{promoTexts[currentTextIndex]}</div>
                    <Link
                        to="/oma-sivu/ilmoitukset/uusi"
                        className="py-2 px-6 mb-5 md:mb-0 inline-block bg-primary text-white text-lg uppercase rounded-full hover:text-white hover:bg-primary-50 transition-all cursor-pointer"
                    >
                        <div>Luo ilmoitus</div>
                    </Link>
                    {!isAuthenticated && (
                        <Link
                            to="/kirjaudu"
                            className="py-2 px-6 inline-block md:ml-4 bg-primary-200 text-white text-lg uppercase rounded-full hover:text-white hover:bg-primary-50 transition-all cursor-pointer"
                        >
                            <div>Rekisteröidy</div>
                        </Link>
                    )}
                </div>
                <div className="m-10 mb-32 w-full md:w-2/3">
                    <AuctionSearchBar />
                </div>
            </div>
        </div>
    );
};

export default Hero;
