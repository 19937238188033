import React, { useEffect, useState } from "react";
import CustomWeekPicker from "../../common/CustomWeekPicker";

import { Form, Tooltip, Select, InputNumber, Input, Checkbox } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { getISOWeek } from "date-fns";

import dayjs from "dayjs";
import api from "../../../services/Api";

const AuctionFormApartment = ({ auction, setAuction }) => {
    const [apartments, setApartments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (auction.property) {
            fetchApartments();
        }
    }, [auction.property]);

    const fetchApartments = async () => {
        setLoading(true);

        try {
            const response = await api.getApartments(auction.property.id);
            setApartments(response);
        } catch (error) {
            console.error("Error fetching apartments:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleApartmentSelect = (value) => {
        const selectedApartment = apartments.find((apartment) => apartment.roomNumber === value);
        if (selectedApartment) {
            setAuction((prevAuction) => ({
                ...prevAuction,
                roomNumber: selectedApartment.roomNumber,
                propertyAttributes: {
                    ...prevAuction.propertyAttributes,
                    area: selectedApartment.area,
                    bedrooms: selectedApartment.bedrooms,
                    hasLoft: selectedApartment.hasLoft,
                    hasBalcony: selectedApartment.hasBalcony,
                    hasSauna: selectedApartment.hasSauna,
                    hasTerrace: selectedApartment.hasTerrace,
                    description: selectedApartment.description,
                    beds: selectedApartment.beds,
                    extrabeds: selectedApartment.extrabeds,
                },
            }));
        }
    };

    const handleDateChange = (date) => {
        let weekNumber = getISOWeek(date) + 1;

        if (weekNumber === 53) {
            //date = dayjs(date).add(1, "week").toDate();
            weekNumber = 1;
        }

        setAuction((prevAuction) => ({ ...prevAuction, propertyWeekStart: date, propertyWeek: weekNumber }));
    };

    return (
        <div>
            <h3 className="font-bold text-lg mt-5">Huoneiston tiedot</h3>
            <div className="grid grid-cols-2 md:grid-cols-7 gap-x-5 mb-5">
                <Form.Item label="Huoneistotunnus" className="col-start-1">
                    {apartments.length > 0 ? (
                        <Select
                            loading={loading}
                            value={auction.roomNumber}
                            onChange={handleApartmentSelect}
                            placeholder="Valitse huoneisto"
                        >
                            {apartments.map((apartment) => (
                                <Select.Option key={apartment.roomNumber} value={apartment.roomNumber}>
                                    {apartment.roomNumber}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        <Input
                            value={auction.propertyAttributes?.apartment || ""}
                            onChange={(e) =>
                                setAuction((prevAuction) => ({
                                    ...prevAuction,
                                    propertyAttributes: {
                                        ...prevAuction.propertyAttributes,
                                        apartment: e.target.value,
                                    },
                                }))
                            }
                        />
                    )}
                </Form.Item>

                <div className="mb-5">
                    <Form.Item label="Huoneita" className="m-0">
                        <InputNumber
                            value={auction.propertyAttributes?.bedrooms || 0}
                            onChange={(value) =>
                                setAuction((prevAuction) => ({
                                    ...prevAuction,
                                    propertyAttributes: {
                                        ...prevAuction.propertyAttributes,
                                        bedrooms: value,
                                    },
                                }))
                            }
                            addonAfter="mh"
                            disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                        />
                    </Form.Item>
                </div>

                <Form.Item label="Pinta-ala">
                    <InputNumber
                        value={auction.propertyAttributes?.area || 0}
                        onChange={(value) =>
                            setAuction((prevAuction) => ({
                                ...prevAuction,
                                propertyAttributes: {
                                    ...prevAuction.propertyAttributes,
                                    area: value,
                                },
                            }))
                        }
                        addonAfter="m²"
                        disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                    />
                </Form.Item>

                <Form.Item label="Vuodepaikat">
                    <InputNumber
                        value={auction.propertyAttributes?.beds || 0}
                        onChange={(value) =>
                            setAuction((prevAuction) => ({
                                ...prevAuction,
                                propertyAttributes: {
                                    ...prevAuction.propertyAttributes,
                                    beds: value,
                                },
                            }))
                        }
                        addonAfter="kpl"
                        disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                    />
                </Form.Item>

                <Form.Item label="Lisävuodepaikat">
                    <InputNumber
                        value={auction.propertyAttributes?.extrabeds || 0}
                        onChange={(value) =>
                            setAuction((prevAuction) => ({
                                ...prevAuction,
                                propertyAttributes: {
                                    ...prevAuction.propertyAttributes,
                                    extrabeds: value,
                                },
                            }))
                        }
                        addonAfter="kpl"
                        disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                    />
                </Form.Item>

                <Form.Item label="Huoneistokuvaus" className="col-span-2">
                    <Input
                        value={auction.propertyAttributes?.description || ""}
                        onChange={(e) =>
                            setAuction((prevAuction) => ({
                                ...prevAuction,
                                propertyAttributes: {
                                    ...prevAuction.propertyAttributes,
                                    description: e.target.value,
                                },
                            }))
                        }
                        disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                    />
                </Form.Item>

                <Checkbox
                    name="hasLoft"
                    onChange={(e) =>
                        setAuction((prevAuction) => ({
                            ...prevAuction,
                            propertyAttributes: {
                                ...prevAuction.propertyAttributes,
                                hasLoft: e.target.checked ? 1 : 0,
                            },
                        }))
                    }
                    checked={auction.propertyAttributes?.hasLoft > 0 ? true : false}
                    required={true}
                    className="col-start-1"
                    disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                >
                    Parvi
                </Checkbox>
                <Checkbox
                    name="hasBalcony"
                    onChange={(e) =>
                        setAuction((prevAuction) => ({
                            ...prevAuction,
                            propertyAttributes: {
                                ...prevAuction.propertyAttributes,
                                hasBalcony: e.target.checked ? 1 : 0,
                            },
                        }))
                    }
                    checked={auction.propertyAttributes?.hasBalcony > 0 ? true : false}
                    required={true}
                    disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                >
                    Parveke
                </Checkbox>
                <Checkbox
                    name="hasSauna"
                    onChange={(e) =>
                        setAuction((prevAuction) => ({
                            ...prevAuction,
                            propertyAttributes: {
                                ...prevAuction.propertyAttributes,
                                hasSauna: e.target.checked ? 1 : 0,
                            },
                        }))
                    }
                    checked={auction.propertyAttributes?.hasSauna > 0 ? true : false}
                    required={true}
                    disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                >
                    Sauna
                </Checkbox>
                <Checkbox
                    name="hasTerrace"
                    onChange={(e) =>
                        setAuction((prevAuction) => ({
                            ...prevAuction,
                            propertyAttributes: {
                                ...prevAuction.propertyAttributes,
                                hasTerrace: e.target.checked ? 1 : 0,
                            },
                        }))
                    }
                    checked={auction.propertyAttributes?.hasTerrace > 0 ? true : false}
                    required={true}
                    disabled={!auction.roomNumber && !auction.propertyAttributes.apartment}
                >
                    Terassi
                </Checkbox>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-6 gap-x-5">
                {auction.property?.ownershipType === "week" && (
                    <>
                        <Form.Item
                            label="Seuraava huoneiston käyttöoikeus"
                            name="propertyWeekStart"
                            required={true}
                            className="col-span-2"
                        >
                            <CustomWeekPicker
                                initialStartDate={
                                    auction.propertyWeekStart ? dayjs(auction.propertyWeekStart).toDate() : null
                                }
                                onDateChange={handleDateChange}
                                disabled={auction.status === "sold"}
                            />
                        </Form.Item>
                        <Form.Item label="Viikko nro" required={true}>
                            <Select
                                name="propertyWeek"
                                value={auction.propertyWeek}
                                onChange={(value) =>
                                    setAuction((prevAuction) => ({ ...prevAuction, propertyWeek: value }))
                                }
                                disabled={!auction.propertyWeekStart}
                            >
                                {Array.from({ length: 52 }, (_, i) => i + 1).map((week) => (
                                    <Select.Option key={week} value={week}>
                                        {week}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </>
                )}
                {auction.auctionType !== "rent" && (
                    <Form.Item
                        label={
                            <>
                                <span>Vastike</span>
                                <Tooltip title="Viimeisin vahvistettu vastikkeen määrä">
                                    <InfoCircleFilled className="ml-2 text-blue-700" style={{ fontSize: "16px" }} />
                                </Tooltip>
                            </>
                        }
                    >
                        <Input
                            name="maintenanceCharge"
                            value={auction.maintenanceCharge}
                            onChange={(e) => setAuction({ ...auction, maintenanceCharge: e.target.value })}
                            addonAfter={auction.property?.ownershipType === "week" ? "€ / vuosi" : "€ / kk"}
                            disabled={!auction.propertyWeekStart}
                        />
                    </Form.Item>
                )}
            </div>
        </div>
    );
};

export default AuctionFormApartment;
