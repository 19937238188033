import React from "react";
import { Button, Input, Form, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const AuctionLinks = ({ links, onLinksChange }) => {
    const handleAddLink = () => {
        const newLinks = [...links, { url: "", description: "" }];
        onLinksChange(newLinks);
    };

    const handleLinkChange = (index, key, value) => {
        const newLinks = [...links];
        newLinks[index][key] = value;
        onLinksChange(newLinks);
    };

    const handleRemoveLink = (index) => {
        const newLinks = links.filter((_, i) => i !== index);
        onLinksChange(newLinks);
    };

    return (
        <div className="m-0">
            <div className="font-bold mb-2">Linkit 3D- tai videoesittelyihin</div>
            {links.map((link, index) => (
                <div key={index} className="md:flex items-center gap-5 mb-2">
                    <Form.Item className="m-0 flex-1" required>
                        <Input
                            placeholder="URL"
                            value={link.url}
                            onChange={(e) => handleLinkChange(index, "url", e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item className="m-0 flex-1" required>
                        <Input
                            placeholder="Linkin selite"
                            value={link.description}
                            onChange={(e) => handleLinkChange(index, "description", e.target.value)}
                        />
                    </Form.Item>
                    <div className="flex justify-end mt-1 md:block md:mt-0">
                        <Popconfirm
                            title="Vahvista linkin poisto"
                            description=""
                            okText="Poista"
                            cancelText="Peruuta"
                            onConfirm={() => handleRemoveLink(index)}
                        >
                            <>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-red-600 cursor-pointer"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                </svg>
                            </>
                        </Popconfirm>
                    </div>
                </div>
            ))}
            <Button type="dashed" onClick={handleAddLink} icon={<PlusOutlined />}>
                Lisää linkki
            </Button>
        </div>
    );
};

export default AuctionLinks;
