export const AuctionTypes = {
    SALE: 'myynti',
    AUCTION: 'huutokauppa',
    RENT: 'vuokraus',
};

export const AuctionTypeLabels = {
    sale: AuctionTypes.SALE,
    auction: AuctionTypes.AUCTION,
    rent: AuctionTypes.RENT,
};