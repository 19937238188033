import React from 'react';

import { Button, Modal } from 'antd';

import AuctionDetails from "../common/AuctionPage/AuctionDetails";
import AuctionGallery from "../common/AuctionPage/AuctionGallery";

const PreviewModal = ({ auction, open, title, onClose }) => {

    return (
        <Modal
        open={open} onCancel={onClose} footer={null} title={title} 
        width={1000}
        >
            <AuctionGallery images={auction.images} />
            <AuctionDetails auction={auction} />
            <div className="text-center mt-5">
                <Button type="primary" className="bg-red-600 text-white" onClick={onClose}>
                    Sulje
                </Button>
            </div>
        </Modal>
    );
};

export default PreviewModal;