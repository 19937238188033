import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Alert } from "antd";
import api from "../services/Api";

const PasswordResetPage = () => {
    const { token } = useParams();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        setLoading(true);
        setError(null);

        try {
            await api.resetPassword({ token, password: values.password });
            setSuccess(true);

            setTimeout(() => {
                navigate("/kirjaudu");
            }, 3000);
        } catch (err) {
            setError("Salasanan vaihtaminen epäonnistui.");
        }

        setLoading(false);
    };

    return (
        <div className="container max-w-screen-2xl m-auto text-lg p-5 md:px-0">
            <h1>Salasanan vaihto</h1>

            {success ? (
                <Alert
                    message="Salasana on vaihdettu onnistuneesti! Sinut ohjataan kirjautumissivulle."
                    type="success"
                    showIcon
                    className="mb-5"
                />
            ) : (
                <Form onFinish={handleSubmit} layout="vertical" className="md:w-1/2">
                    {error && <Alert message={error} type="error" showIcon className="mb-4" />}

                    <Form.Item
                        label="Uusi salasana"
                        name="password"
                        rules={[
                            { required: true, message: "Anna uusi salasana" },
                            { min: 6, message: "Salasanan täytyy olla vähintään 6 merkkiä pitkä" },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Vahvista uusi salasana"
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: "Vahvista uusi salasana" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error("Salasanat eivät täsmää"));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            Vaihda salasana
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default PasswordResetPage;
