import React, { useEffect, useState } from "react";

import api from "../../services/Api";
import { Modal, Popconfirm, Spin, Table } from "antd";

const User = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);

    useEffect(() => {
        setLoading(true);

        const fetchUser = async () => {
            try {
                const response = await api.getUser();
                setUser(response);
            } catch (error) {
                console.error("Error fetching user:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    return (
        <>
            {loading ? (
                <Spin />
            ) : (
                <>
                    <h1>{user.name}</h1>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="font-bold">Sähköposti</div>{" "}
                        <div>
                            <a href={`mailto:${user.email}`} className="text-blue-700 underline">
                                {user.email}
                            </a>
                        </div>
                        <div className="font-bold">Puhelinnumero</div> <div>{user.phonenumber}</div>
                        <div className="font-bold">Osoite</div> <div>{user.address}</div>
                        <div className="font-bold">Postinumero</div> <div>{user.postalCode}</div>
                        <div className="font-bold">Kaupunki</div> <div>{user.city}</div>
                        <div className="font-bold">Maa</div> <div>{user.country}</div>
                    </div>

                    {user.company && (
                        <div>
                            <h2>Yritys</h2>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="font-bold">Yrityksen nimi</div> <div>{user.company.name}</div>
                                <div className="font-bold">Y-tunnus</div> <div>{user.company.businessId}</div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default User;
