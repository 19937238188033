import { Button, Form, Input } from "antd";
import React, { useContext, useState } from "react";
import { message } from "antd";

import { AuthContext } from "../../../context/AuthContext";

import api from "../../../services/Api";

const AuctionChatBox = ({ auction }) => {
    const { isAuthenticated } = useContext(AuthContext);

    const [messageContent, setMessageContent] = useState("");
    const [sendingMessage, setSendingMessage] = useState(false);

    const handleSendChatMessage = async () => {
        const messageContainer = document.querySelector(".send-message");

        if (!messageContent.trim()) {
            message.error("Please enter a message");
            return;
        }

        setSendingMessage(true);

        const payload = {
            content: messageContent,
            auction_number: auction.number,
            chat_uuid: null,
        };

        try {
            await api.sendChatMessage(payload);
            setMessageContent("");
            messageContainer.innerHTML =
                '<div className="bg-green-600 rounded h-36 flex flex-col items-center justify-center text-white">' +
                '<div className="text-center font-bold">Viesti lähetetty!</div>' +
                '<div className="text-center">Voit jatkaa keskustelua<br />' +
                '<a className="underline text-green-100 hover:text-white hover:underline" href="/oma-sivu/viestit">omassa profiilissasi</a></div>' +
                "</div>";
        } catch (error) {
            console.error("Error sending message:", error);
            message.error("Virhe viestin lähetyksessä");
        } finally {
            setSendingMessage(false);
        }
    };

    return (
        <>
            {isAuthenticated && (
                <>
                    <h3 className="text-white mb-2 mt-5 font-normal">Lähetä viesti myyjälle</h3>
                    <div className="send-message">
                        <Form layout="vertical" onFinish={handleSendChatMessage}>
                            <Form.Item>
                                <Input.TextArea
                                    rows={6}
                                    value={messageContent}
                                    onChange={(e) => setMessageContent(e.target.value)}
                                    placeholder="Kirjoita viesti..."
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    className="bg-emerald-500 text-white font-bold w-full"
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={sendingMessage}
                                    disabled={sendingMessage}
                                >
                                    Lähetä viesti
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </>
            )}
        </>
    );
};

export default AuctionChatBox;
