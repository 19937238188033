import React, { useState, useEffect } from "react";
import { Upload, Button, Input, message, Popconfirm, Form, Tooltip } from "antd";
import { QuestionCircleFilled, UploadOutlined } from "@ant-design/icons";
import api from "../../services/Api";

const FileUpload = ({ initialFiles = [], onFilesChange, auctionId = null, propertyId = null, disabled = null }) => {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        const validInitialFiles = Array.isArray(initialFiles) ? initialFiles : [];

        const initialDescriptions = {};
        validInitialFiles.forEach((file) => {
            initialDescriptions[file.id] = file.description || "";
        });
        setFiles(
            validInitialFiles.map((file) => ({
                ...file,
                description: initialDescriptions[file.id] || "",
            }))
        );
    }, [initialFiles]);

    const handleFileUpload = async ({ file, onSuccess, onError }) => {
        setUploading(true);

        const tempFile = {
            id: Date.now(),
            filename: file.name,
            url: "",
            description: "",
            isUploading: true,
        };

        const tempFiles = [...files, tempFile];
        setFiles(tempFiles);
        onFilesChange(tempFiles);

        const params = {
            auctionId: auctionId,
            propertyId: propertyId,
            folderId: null
        }

        try {
            const response = await api.uploadFile(file, params);
            const uploadedFile = {
                id: response.id,
                filename: response.filename,
                url: response.url,
                description: "",
            };

            const updatedFiles = tempFiles.map((f) => (f.id === tempFile.id ? uploadedFile : f));

            setFiles(updatedFiles);
            onFilesChange(updatedFiles);
            setUploading(false);
            onSuccess("ok");
        } catch (error) {
            console.error("Error uploading file:", error);
            message.error("Failed to upload file");

            const updatedFiles = tempFiles.filter((f) => f.id !== tempFile.id);
            setFiles(updatedFiles);
            onFilesChange(updatedFiles);

            setUploading(false);
            onError(error);
        }
    };

    const handleDescriptionChange = (id, description) => {
        const updatedFiles = files.map((file) => (file.id === id ? { ...file, description } : file));
        setFiles(updatedFiles);
        onFilesChange(updatedFiles);
    };

    const handleRemove = (file) => {
        const updatedFiles = files.filter((item) => item.id !== file.uid);
        setFiles(updatedFiles);
        onFilesChange(updatedFiles);
    };

    const handleFileDelete = async (fileId) => {
        try {
            await api.deleteFile(fileId);
            const updatedFiles = files.filter((file) => file.id !== fileId);
            setFiles(updatedFiles);
            onFilesChange(updatedFiles);
        } catch (error) {
            console.error("Error deleting file:", error);
            message.error("Failed to delete file");
        }
    };

    return (
        <Form.Item
            label={
                <>
                    <span>Tiedostot</span>
                    <Tooltip title="Esim. Yhtiöjärjestys, aluekartta, pohjakuva, isännöitsijäntodistus jne...">
                        <div className="text-lg"><QuestionCircleFilled className="text-blue-700 ml-2" /></div>
                    </Tooltip>
                </>
            }
            className="m-0"
        >
            {files.map((file, index) => (
                <div key={index} className="flex items-center gap-5 mb-2">
                    <Form.Item className="m-0 flex-2" required>
                        <Input value={file.filename} readOnly />
                    </Form.Item>
                    <Form.Item className="m-0 flex-1" required>
                        <Input
                            placeholder="Kuvaus"
                            value={file.description || ""}
                            onChange={(e) => handleDescriptionChange(file.id, e.target.value)}
                        />
                    </Form.Item>
                    <div className="cursor-pointer">
                        <Popconfirm
                            title="Vahvista tiedoston poisto"
                            description=""
                            okText="Poista"
                            cancelText="Peruuta"
                            onConfirm={() => handleFileDelete(file.id)}
                        >
                            <>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-red-600 cursor-pointer"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                </svg>
                            </>
                        </Popconfirm>
                    </div>
                </div>
            ))}
            <Upload
                customRequest={handleFileUpload}
                listType="picture"
                onRemove={handleRemove}
                disabled={uploading}
                showUploadList={false}
            >
                <Button icon={<UploadOutlined />} disabled={uploading || disabled}>
                    {uploading ? "Uploading..." : "Lisää tiedosto (pdf)"}
                </Button>
                
            </Upload>
        </Form.Item>
    );
};

export default FileUpload;
