import React, { useState, useEffect } from "react";
import { Button, Image, Form, Select, Upload, Tooltip, Modal, Spin } from "antd";
import { CameraOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import SortableList, { SortableItem } from "react-easy-sort";

import PropertySelectModal from "../../modals/PropertySelectModal";
import PropertyFormModal from "../../modals/PropertyFormModal";
import GalleryModal from "../../modals/GalleryModal";

import arrayMove from "array-move";
import api from "../../../services/Api";
import CollapsibleHtmlContent from "../../common/CollapsibleHtmlContent";
import InfoBar from "../../common/InfoBar";
import AuctionFormApartment from "./AuctionFormApartment";

const AuctionFormProperty = ({ auction, setAuction }) => {
    const [propertyModalOpen, setPropertyModalOpen] = useState(false);
    const [areas, setAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState(null);
    const [galleryModalOpen, setGalleryModalOpen] = useState(false);
    const [propertyFormModalOpen, setPropertyFormModalOpen] = useState(false);

    useEffect(() => {
        fetchAreas();
    }, []);

    const fetchAreas = async () => {
        try {
            const response = await api.getAvailableAreas();
            setAreas(response);
        } catch (error) {
            console.error("Error fetching areas:", error);
        }
    };

    const handlePropertySelect = (property) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            propertyId: property.id,
            property: property,
            propertyFiles: property.files,
        }));
    };

    const onSortEnd = (oldIndex, newIndex) => {
        setAuction((prevAuction) => {
            const newImages = arrayMove([...prevAuction.images], oldIndex, newIndex);
            return {
                ...prevAuction,
                images: newImages,
            };
        });
    };

    useEffect(() => {
        auction.images.forEach((image, index) => {
            image.sort_num = index + 1;
        });
    }, [auction.images]);

    const handleAreaChange = (value) => {
        setSelectedArea(value);
        setPropertyModalOpen(true);
    };

    const uploadProps = {
        name: "file",
        multiple: true,
        showUploadList: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            const placeholderId = `temp-${file.uid}`;
            const placeholderImage = {
                id: placeholderId,
                urls: { thumbnail: URL.createObjectURL(file) }, // Local preview URL
                placeholder: true, // Flag to indicate this is a placeholder
            };

            // Add placeholder to auction images
            setAuction((prevAuction) => ({
                ...prevAuction,
                images: [...prevAuction.images, placeholderImage],
            }));

            const params = {
                propertyId: auction.property.id,
                auctionId: auction.uuid,
            };

            try {
                const response = await api.uploadFile(file, params);
                onSuccess(response);

                setAuction((prevAuction) => ({
                    ...prevAuction,
                    images: prevAuction.images.map((img) => (img.id === placeholderId ? response.file : img)),
                }));
            } catch (error) {
                onError(error);

                setAuction((prevAuction) => ({
                    ...prevAuction,
                    images: prevAuction.images.filter((img) => img.id !== placeholderId),
                }));
            } finally {

            }
        },
        onChange(info) {
            if (info.file.status !== "uploading") {
            }
            if (info.file.status === "done") {
            } else if (info.file.status === "error") {
            }
        },
    };

    const handleRemovePhotoFromAuction = (e) => {
        e.stopPropagation();

        const imageId = parseInt(e.currentTarget.getAttribute("data-image-id"));
        const updatedImages = auction.images.filter((image) => image.id !== imageId);

        setAuction((prevAuction) => ({
            ...prevAuction,
            images: updatedImages,
        }));
    };

    return (
        <>
            <div className="text-xl font-bold mb-2">Kohteen tiedot</div>
            {!auction.property && (
                <>
                    <InfoBar>
                        Valitse alue, jolla kohteesi sijaitsee ja sen jälkeen valitse kohde. Voit myös lisätä oman
                        kohteesi, mikäli kohdettasi ei löydy tiedoistamme.
                    </InfoBar>

                    <Select
                        value={selectedArea}
                        onChange={handleAreaChange}
                        placeholder={<div className="text-gray-500">Valitse sijainti</div>}
                        className="w-32 mb-3"
                    >
                        {areas.map((area) => (
                            <Select.Option key={area} value={area}>
                                {area}
                            </Select.Option>
                        ))}
                    </Select>

                    <span className="mx-5">tai</span>

                    <Button type="primary" onClick={() => setPropertyFormModalOpen(true)} className="mb-5">
                        Lisää oma kohde
                    </Button>
                </>
            )}
            {auction.property?.userProperty && (
                <Button type="primary" onClick={() => setPropertyFormModalOpen(true)} className="mb-5">
                    Muokkaa kohteen tietoja
                </Button>
            )}
            <Form.Item rules={[{ required: true, message: "Kohde on pakollinen" }]}>
                {auction.property && (
                    <div>
                        <div className="bg-white md:flex md:justify-between gap-2 mb-5">
                            <div className="p-3">
                                <div className="text-xl font-bold mb-2">{auction.property.title}</div>
                                <div className="text-base text-gray-700 mb-2">
                                    <strong>Omistusmuoto</strong>
                                    <br />
                                    {auction.property.ownershipType === "week" && "Viikko-osake"}
                                    {auction.property.ownershipType === "other" && "Muu omistus"}
                                    {auction.property.ownershipType !== "week" &&
                                        auction.property.ownershipType !== "other" &&
                                        auction.property.ownershipType + " osaomistus"}
                                </div>
                                <div className="text-base text-gray-700 mb-2">
                                    <strong>Kohteen osoite</strong>
                                    <br />
                                    {auction.property.address}, {auction.property.zipcode} {auction.property.city}
                                </div>
                                {auction.property.serviceAddress && !auction.property.userProperty && (
                                    <div className="text-base text-gray-700">
                                        <strong>Avainpalvelun osoite</strong>
                                        <br />
                                        {auction.property.serviceAddress}, {auction.property.serviceZipcode}{" "}
                                        {auction.property.serviceCity}
                                    </div>
                                )}
                                {auction.property.description && (
                                    <>
                                        <strong>Kohteen kuvaus</strong>
                                        <CollapsibleHtmlContent htmlContent={auction.property.description} />
                                    </>
                                )}
                            </div>
                            <div className="p-3">
                                {auction.images && auction.images[0] ? (
                                    <Image width={300} height={200} src={auction.images[0]?.urls.medium} />
                                ) : (
                                    <div
                                        style={{ width: "300px", height: "200px" }}
                                        className="bg-gray-50 flex justify-center items-center"
                                    >
                                        <CameraOutlined className="text-gray-400" style={{ fontSize: "50px" }} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <Button type="primary" onClick={() => setGalleryModalOpen(true)} className="mb-5 mr-5">
                                Valitse ilmoituksen kuvat
                            </Button>
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />}>Lataa omia kuvia</Button>
                            </Upload>
                            <SortableList
                                onSortEnd={onSortEnd}
                                className="list flex flex-wrap gap-5 mt-5 user-select-none border border-dashed p-5 rounded border-gray-500 w-full"
                                draggedItemClassName="dragged"
                            >
                                {auction.images.length ? (
                                    auction.images?.map((image) => (
                                        <SortableItem key={image.id}>
                                            <div
                                                style={{
                                                    width: "175px",
                                                    height: "125px",
                                                    overflow: "hidden",
                                                    border: "solid 1px #ddd",
                                                    position: "relative",
                                                    userSelect: "none",
                                                    cursor: "grab",
                                                    opacity: image.placeholder ? 0.5 : 1,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                    backgroundImage: `url(${image.urls.thumbnail})`,
                                                }}
                                            >
                                                {image.placeholder && (
                                                    <div className="flex items-center justify-center text-black w-full h-full">
                                                        <Spin
                                                            size="large"
                                                            indicator={<LoadingOutlined className="text-white" spin />}
                                                        />
                                                    </div>
                                                )}
                                                {!image.placeholder && (
                                                    <Tooltip title="Poista kuva">
                                                        <div
                                                            onClick={() =>
                                                                setAuction((prevAuction) => ({
                                                                    ...prevAuction,
                                                                    images: prevAuction.images.filter(
                                                                        (img) => img.id !== image.id
                                                                    ),
                                                                }))
                                                            }
                                                            className="absolute top-1 right-1 bg-red-600 text-white p-1 rounded cursor-pointer"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="1.5"
                                                                stroke="currentColor"
                                                                className="size-4"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M6 18 18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            {/* <ImageThumbnail
                                                image={image}
                                                handleRemovePhotoFromAuction={handleRemovePhotoFromAuction}
                                            /> */}
                                        </SortableItem>
                                    ))
                                ) : (
                                    <div className="text-xl">Ei kuvia valittu</div>
                                )}
                            </SortableList>
                        </div>
                        <AuctionFormApartment auction={auction} setAuction={setAuction} />
                    </div>
                )}
            </Form.Item>

            <PropertySelectModal
                open={propertyModalOpen}
                onClose={() => setPropertyModalOpen(false)}
                onSelectProperty={handlePropertySelect}
                selectedArea={selectedArea}
                onAreaChange={setSelectedArea}
            />

            <PropertyFormModal
                open={propertyFormModalOpen}
                onClose={() => setPropertyFormModalOpen(false)}
                property={auction.property}
                onSelectProperty={handlePropertySelect}
            />

            <GalleryModal
                open={galleryModalOpen}
                onClose={() => setGalleryModalOpen(false)}
                propertyId={auction.property?.id}
                onSelectImages={(images) =>
                    setAuction((prevAuction) => ({
                        ...prevAuction,
                        images: images,
                    }))
                }
                selectedImages={auction.images}
            />
        </>
    );
};

export default AuctionFormProperty;
