import React, { useState, useEffect, useContext } from "react";
import { Form, Button, message, Spin, Input, Select, Popconfirm } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import api from "../../services/Api";
import { AuthContext } from "../../context/AuthContext";
import SettingEditModal from "../../components/modals/SettingEditModal";

const Settings = () => {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { user, hasRole } = useContext(AuthContext);

    // Modal state
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState(null);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await api.getSettings();
            setSettings(response);
        } catch (error) {
            message.error("Error fetching settings");
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            await api.saveSettings(settings);
            message.success("Settings saved successfully");
        } catch (error) {
            message.error("Error saving settings");
        } finally {
            setSaving(false);
        }
    };

    const handleInputChange = (key, property, value) => {
        setSettings((prevSettings) =>
            prevSettings.map((setting) => (setting.id === key ? { ...setting, [property]: value } : setting))
        );
    };

    const handleRemoveSetting = async (key) => {
        try {
            await api.deleteSetting(key);
            setSettings((prevSettings) => prevSettings.filter((setting) => setting.id !== key));
            message.success("Setting removed successfully");
        } catch (error) {
            message.error("Error removing setting");
        }
    };

    const openModal = (setting) => {
        setSelectedSetting(setting);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedSetting(null);
    };

    const saveSetting = () => {
        setModalOpen(false);
        // Optionally: Save to server here if desired.
    };

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Settings</h1>
            {loading ? (
                <Spin />
            ) : (
                <Form layout="horizontal" onFinish={handleSave}>
                    {settings.map((setting) => (
                        <Form.Item key={setting.id}>
                            <div
                                className="font-bold cursor-pointer text-primary"
                                onClick={() => openModal(setting)}
                            >
                                {setting.label}
                            </div>
                            {hasRole(user.roles, "ROLE_GOD") && (
                                <div className="flex gap-5 mb-2">
                                    <Input
                                        value={setting.label}
                                        onChange={(e) => handleInputChange(setting.id, "label", e.target.value)}
                                    />
                                    <Input
                                        value={setting.name}
                                        onChange={(e) => handleInputChange(setting.id, "name", e.target.value)}
                                    />
                                    <Select
                                        value={setting.type}
                                        onChange={(value) => handleInputChange(setting.id, "type", value)}
                                    >
                                        <Select.Option value="text">Text</Select.Option>
                                        <Select.Option value="number">Number</Select.Option>
                                        <Select.Option value="string">String</Select.Option>
                                    </Select>
                                    <Popconfirm
                                        title="Are you sure you want to delete this setting?"
                                        onConfirm={() => handleRemoveSetting(setting.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteFilled className="text-red-600" />
                                    </Popconfirm>
                                </div>
                            )}
                        </Form.Item>
                    ))}

                    {hasRole(user.roles, "ROLE_GOD") && (
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() =>
                                    setSettings((prevSettings) => [
                                        ...prevSettings,
                                        {
                                            id: `new_setting_${settings.length + 1}`, // Unique id for new setting
                                            name: "new_setting",
                                            label: "New Setting",
                                            type: "text",
                                            value: "",
                                        },
                                    ])
                                }
                            >
                                Add Setting
                            </Button>
                        </Form.Item>
                    )}

                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="submit" loading={saving}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            )}

            {/* SettingEditModal for editing individual settings */}
            {selectedSetting && (
                <SettingEditModal
                    open={modalOpen}
                    setting={selectedSetting}
                    onSave={saveSetting}
                    onCancel={closeModal}
                    handleInputChange={handleInputChange}
                />
            )}
        </div>
    );
};

export default Settings;
