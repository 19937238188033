import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import api from "../../services/Api";

const ForgotPasswordForm = ({ onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    
    const handleSubmit = async (values) => {
        setLoading(true);

        try {
            await api.requestPasswordReset(values.email);
            setSuccess(true);
        } catch (err) {
            setError("Unable to send reset email.");
        } finally {
            setLoading(false);
            form.resetFields();
        }
    };

    return (
        <Form onFinish={handleSubmit}>
            {success && (
                <>
                    <div className="mb-4">
                        Palautuslinkki on lähetetty sähköpostiisi. Mikäli et saa sähköpostia muutaman minuutin kuluessa,
                        tarkista roskapostikansio. Tarvittaessa voit ottaa yhteyttä asiakaspalveluumme.
                    </div>
                </>
            )}
            {error && <Alert message={error} type="error" />}
            <Form.Item
                label="Sähköposti"
                name="email"
                rules={[
                    { required: true, message: "Sähköposti on pakollinen" },
                    { type: "email", message: "Anna kelvollinen sähköpostiosoite" },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Lähetä palautuslinkki
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ForgotPasswordForm;
