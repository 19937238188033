import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Drawer } from "antd";
import { DeleteFilled } from "@ant-design/icons";

import AuctionCountdownTimer from "./AuctionCountdownTimer";

import api from "../../services/Api";

const FavoritesDrawer = ({ open, onClose }) => {
    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            fetchFavorites();
        }
    }, [open]);

    const fetchFavorites = async () => {
        setLoading(true);

        try {
            const response = await api.getFavoriteAuctions();
            setFavorites(response);
        } catch (error) {
            console.error("Error fetching auctions:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFavorite = async (auctionNumber, e) => {
        e.preventDefault();

        try {
            await api.toggleFavorite(auctionNumber);
        
            // Slide out the removed favorite
            const removedFavoriteContainer = document.querySelector(`[data-favorite="${auctionNumber}"]`);
            const removedFavorite = removedFavoriteContainer.querySelector("div");

            if (removedFavorite) {
                removedFavoriteContainer.style.height = removedFavorite.offsetHeight + "px";
                removedFavoriteContainer.style.display = "block";
                removedFavorite.style.position = "absolute";
                removedFavorite.style.transition = "all 1s ease";
                removedFavorite.style.transform = "translateX(150%)";
                
                setTimeout(() => {
                    // remove container from dom
                    removedFavoriteContainer.style.display = "none";
                }, 800);
            }


        } catch (error) {
            console.error("Error removing favorite:", error);
        }
    };

    return (
        <Drawer title="Suosikit" onClose={onClose} open={open} loading={loading}>
            <div className="-m-6">
                {loading && <div>Ladataan...</div>}
                {favorites?.map((auction) => (
                    <Link data-favorite={auction.number} key={auction.number} to={`/kohde/${auction.number}`}>
                        <div className="text-gray-800 flex items-start p-3 border-b border-b-gray-300 opacity-80 hover:opacity-100 hover:bg-gray-200 transition-all transition-duration-100">
                            <div
                                className="flex-shrink-0 rounded-sm mt-1"
                                style={{
                                    backgroundImage: `url(${auction.images[0].urls.medium})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    height: "40px",
                                    width: "60px",
                                }}
                            ></div>
                            <div className="pl-3 flex-1 text-xs">
                                <div className="text-primary text-sm font-semibold truncate">{auction.title}</div>
                                <div className="">
                                    {auction.ownershipType === "week" ? (
                                        <>Vk {auction.propertyWeek}</>
                                    ) : (
                                        <>{auction.ownershipType}</>
                                    )}
                                </div>
                                <div>
                                    {auction.auctionType === "auction" &&
                                        `Korkein tarjous: ${auction.currentPrice} € (${auction.bidCount} tarjousta)`}
                                    {auction.auctionType === "sale" && `Hinta: ${auction.startPrice} €`}
                                    {auction.auctionType === "rent" && `Hinta: ${auction.startPrice} € / vko`}
                                </div>
                                <div>
                                    <span className="mr-1">Päättyy:</span>
                                    <strong>
                                        <AuctionCountdownTimer auction={auction} />
                                    </strong>
                                </div>
                            </div>
                            <div className="absolute right-3 text-red-600 text-lg cursor-pointer">
                                <DeleteFilled onClick={(e) => handleRemoveFavorite(auction.number, e)} />
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </Drawer>
    );
};

export default FavoritesDrawer;
