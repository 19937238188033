import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Spin, message } from "antd";

import api from "../../services/Api";

const ContactMessagesPage = () => {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        try {
            setLoading(true);
            const data = await api.getContactMessages();
            setMessages(data);
        } catch (error) {
            message.error("Failed to fetch messages.");
        } finally {
            setLoading(false);
        }
    };

    const handleRowClick = (record) => {
        setSelectedMessage(record);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedMessage(null);
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: "Sender Name",
            dataIndex: "senderName",
            key: "sender_name",
        },
        {
            title: "Sender Email",
            dataIndex: "senderEmail",
            key: "sender_email",
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "created_at",
            render: (timestamp) => new Date(timestamp * 1000).toLocaleString('fi-FI'),
        },
    ];

    return (
        <div>
            <h1 className="text-2xl mb-4">Yhteysviestit</h1>

            {loading ? (
                <Spin />
            ) : (
                <Table
                    columns={columns}
                    dataSource={messages}
                    rowKey="id"
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                    })}
                    pagination={{ pageSize: 10 }}
                />
            )}

            {/* Modal to show message details */}
            <Modal
                title="Message Details"
                visible={isModalOpen}
                onCancel={closeModal}
                footer={[
                    <Button key="close" onClick={closeModal}>
                        Close
                    </Button>,
                ]}
            >
                {selectedMessage && (
                    <div>
                        <p><strong>Lähettäjä:</strong> {selectedMessage.senderName}</p>
                        <p><strong>Sähköposti:</strong> {selectedMessage.senderEmail}</p>
                        <p><strong>Lähetetty:</strong> {new Date(selectedMessage.createdAt.date * 1000).toLocaleString('fi-FI')}</p>
                        <p><strong>Sisältö:</strong></p>
                        <div dangerouslySetInnerHTML={{ __html: selectedMessage.content }} />
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default ContactMessagesPage;
