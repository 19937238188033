import React, { useContext, useState, useEffect } from "react";
import { Modal, Tabs } from "antd";
import { ModalContext } from "../../context/ModalContext";
import LoginForm from "../forms/LoginForm";
import ProfileForm from "../forms/ProfileForm";
import ForgotPasswordForm from "../forms/ForgotPasswordForm"; // Import the new ForgotPasswordForm

const { TabPane } = Tabs;

const LoginModal = () => {
    const { isLoginModalOpen, hideLoginModal, initialTab } = useContext(ModalContext);
    const [activeTab, setActiveTab] = useState(initialTab);

    useEffect(() => {
        setActiveTab(initialTab);
    }, [initialTab]);

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <Modal
            title={activeTab === "login" ? "Kirjaudu" : activeTab === "forgot-password" ? "Resetoi Salasana" : "Rekisteröidy"}
            open={isLoginModalOpen}
            onCancel={hideLoginModal}
            footer={null}
            width={700}
        >
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Kirjaudu" key="login">
                    <LoginForm onLoginSuccess={hideLoginModal} onForgotPassword={() => setActiveTab('forgot-password')} />
                </TabPane>
                <TabPane tab="Rekisteröidy" key="register">
                    <ProfileForm onSaveSuccess={hideLoginModal} />
                </TabPane>
                <TabPane tab="Unohtunut salasana" key="forgot-password">
                    <ForgotPasswordForm />
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default LoginModal;
