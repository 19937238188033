import React from "react";
import { Modal, Input, InputNumber, Button } from "antd";
import CKEditorWrapper from "../../components/common/CKEditorWrapper";

const SettingEditModal = ({ open, setting, onSave, onCancel, handleInputChange }) => {
    const handleChange = (property, value) => {
        handleInputChange(setting.id, property, value);
    };

    return (
        <Modal
            title={`Edit Setting: ${setting.label}`}
            open={open}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={onSave}>
                    Save
                </Button>,
            ]}
        >
            <div className="font-bold mb-2">{setting.label}</div>
            {setting.type === "text" ? (
                <CKEditorWrapper
                    content={setting.value}
                    setContent={(content) => handleChange("value", content)}
                />
            ) : setting.type === "number" ? (
                <InputNumber
                    type="number"
                    value={setting.value}
                    onChange={(value) => handleChange("value", value)}
                />
            ) : setting.type === "string" ? (
                <Input
                    value={setting.value}
                    onChange={(e) => handleChange("value", e.target.value)}
                />
            ) : null}
        </Modal>
    );
};

export default SettingEditModal;
