import React from "react";
import { InfoCircleFilled } from "@ant-design/icons";

const InfoBar = ({ children }) => {
    return (
        <div className="mb-2 border border-blue-200 bg-blue-500 rounded-md px-2 py-1 text-white">
            <InfoCircleFilled className="mr-1 text-white" />
            {children}
        </div>
    );
}

export default InfoBar;