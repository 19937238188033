import React from "react";
import AuctionForm from "../../components/forms/auction/AuctionForm";

const Auction = () => {
    return (
        <>
            <AuctionForm />
        </>
    );
};

export default Auction;
