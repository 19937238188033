import React from "react";

import { ArrowDownOutlined } from "@ant-design/icons";

import AuctionCountdownTimer from "../AuctionCountdownTimer";

const AuctionPriceInfo = ({ auction }) => {
    return (
        <div className="bg-emerald-300 p-4 text-lg rounded mb-5">
            {auction.type === "auction" && (
                <>
                    {auction.bids.bids.length === 0 && (
                        <div className="mb-5">
                            <div className="font-bold text-sm">Ei vielä tarjouksia, lähtöhinta:</div>
                            <div className="text-5xl font-bold mb-5">{auction.startPrice} €</div>
                        </div>
                    )}

                    {auction.bids.bids?.length > 0 && (
                        <>
                            <div className="uppercase">Korkein tarjous</div>
                            <div className="text-5xl font-bold mb-5">{auction.bids.bids[0].amount} €</div>
                        </>
                    )}
                    {auction.status === "expired" ? (
                        <div>Myynti on päättynyt</div>
                    ) : (
                        <>
                            <div className="uppercase">Aikaa jäljellä</div>
                            <div className="text-2xl font-bold">
                                <AuctionCountdownTimer auction={auction} />
                            </div>
                            <div className="text-sm mt-2 text-emerald-700">
                                Huutokauppa päättyy {new Date(auction.ends.date).toLocaleString("fi-FI")}
                            </div>
                        </>
                    )}
                </>
            )}
            {auction.type === "sale" && (
                <>
                    <div className="uppercase">Myyntihinta</div>
                    <div className="text-5xl font-bold mb-5">{auction.startPrice} &euro;</div>
                    {auction.originalPrice && parseInt(auction.originalPrice) > parseInt(auction.startPrice) && (
                        <div className="rounded-full bg-green-600 text-white mr-2 px-2 text-base flex items-center mt-1">
                            <ArrowDownOutlined className="mr-1" />
                            Alkuperäinen hinta: {auction.originalPrice} &euro;
                        </div>
                    )}
                    <div className="uppercase">Viikko</div>
                    <div className="text-3xl">{auction.propertyWeek}</div>
                </>
            )}
            {auction.type === "rent" && (
                <>
                    <div className="uppercase">Vuokra</div>
                    <div className="text-5xl font-bold flex items-center">{auction.startPrice} &euro; / vko</div>
                    {auction.originalPrice && parseInt(auction.originalPrice) > parseInt(auction.startPrice) && (
                        <div className="rounded-full bg-green-600 text-white mr-2 px-2 text-base flex items-center mt-1">
                            <ArrowDownOutlined className="mr-1" />
                            Alkuperäinen hinta: {auction.originalPrice} &euro; / vko
                        </div>
                    )}
                    <div className="uppercase mt-5">Viikko</div>
                    <div className="text-3xl">{auction.propertyWeek}</div>
                </>
            )}
        </div>
    );
};

export default AuctionPriceInfo;
