import React from "react";

import { Modal, Form, Input, Button, message } from "antd";

import api from "../../services/Api";

const PasswordChangeModal = ({ open, onClose }) => {
    const [form] = Form.useForm();

    const handlePasswordChange = async (values) => {
        try {
            await api.changePassword({
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
            });

            message.success("Salasana vaihdettu onnistuneesti!");
            form.resetFields();
            
            onClose();
        } catch (error) {
            message.error(error.response?.data?.message || "Salasanan vaihto epäonnistui.");
        }
    };

    return (
        <Modal
            title="Vaihda salasana"
            open={open}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Peruuta
                </Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()}>
                    Tallenna
                </Button>,
            ]}
        >
            <Form form={form} name="password-change" onFinish={handlePasswordChange}>
                <Form.Item
                    name="oldPassword"
                    label="Vanha salasana"
                    rules={[{ required: true, message: "Syötä vanha salasana!" }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="Uusi salasana"
                    rules={[{ required: true, message: "Syötä uusi salasana!" }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Vahvista uusi salasana"
                    dependencies={["newPassword"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Vahvista uusi salasana!",
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue("newPassword") === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject("Salasanat eivät täsmää!");
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default PasswordChangeModal;