import React, { useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

const CollapsibleHtmlContent = ({ htmlContent, initialRows = 3 }) => {
    const [expanded, setExpanded] = useState(false);

    // Function to toggle expand/collapse
    const toggleExpand = () => setExpanded((prevExpanded) => !prevExpanded);

    return (
        <div>
            <div
                className={`${expanded ? "" : "text-ellipsis overflow-hidden max-h-24"}`}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
            <div onClick={toggleExpand} className="mt-2 text-blue-700 cursor-pointer">
                {expanded ? (
                    <>
                        Näytä vähemmän
                        <CaretUpOutlined className="ml-2" />
                    </>
                ) : (
                    <>
                        Näytä lisää
                        <CaretDownOutlined className="ml-2" />
                    </>
                )}
            </div>
        </div>
    );
};

export default CollapsibleHtmlContent;
