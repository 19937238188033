import React from "react";

const ChatList = ({ chats, fetchMessages }) => {
    if (chats.length === 0) {
        return <div>Ei viestejä</div>;
    }

    return (
        <>
            {chats.map((chat) => (
                <div
                    key={chat.uuid}
                    className="cursor-pointer rounded bg-gray-200 p-2 mb-2 hover:bg-blue-200 transition-colors duration-200"
                    onClick={fetchMessages}
                    data-id={chat.uuid}
                >
                    <div className="relative">
                        {new Date(chat.messages[chat.messages.length - 1]?.createdAt.date).toLocaleDateString()}
                        {chat.hasUnreadMessages && (
                            <span className="bg-green-500 text-white rounded-full right-0 top-0 h-3 w-3 absolute"></span>
                        )}
                    </div>
                    <h4 className="font-bold">{chat.propertyTitle}</h4>
                    {/* <div className="">{chat.messages[chat.messages.length - 1]?.content}</div> */}
                </div>
            ))}
        </>
    );
};

export default ChatList;
