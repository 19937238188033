import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button, Spin } from "antd";

import { ModalContext } from "../context/ModalContext";

import api from "../services/Api";

const UserConfirmPage = () => {
    const { uuid } = useParams();
    const { showLoginModal } = useContext(ModalContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [confirmationStatus, setConfirmationStatus] = useState(null); // Track confirmation status

    useEffect(() => {
        const confirmUser = async () => {
            setLoading(true);

            try {
                await api.confirmUser(uuid);
                setConfirmationStatus("success");
            } catch (error) {
                setConfirmationStatus("error");
            } finally {
                setLoading(false);
            }
        };

        // Automatically confirm the user on page load
        if (uuid) {
            confirmUser();
        }
    }, [uuid, navigate]);

    return (
        <div className="container max-w-screen-2xl m-auto text-lg p-5 md:px-0">
            <h1>Käyttäjätilin vahvistus</h1>
            {loading ? (
                <Spin />
            ) : confirmationStatus === "success" ? (
                <>
                    <div className="text-green-700 mb-5">Käyttäjätilisi on nyt vahvistettu.</div>

                    <Button className="bg-emerald-300" size="large" onClick={() => showLoginModal("login")}>
                        Kirjaudu sisään
                    </Button>
                </>
            ) : (
                <>
                    <div className="text-red-700 mb-5">Vahvistus epäonnistui</div>
                    <p>
                        Tarkista, että käyttämäsi linkki on oikea tai ota yhteyttä{" "}
                        <Link to={`/yhteystiedot`} className="underline text-blue-500">asiakaspalveluun</Link>.
                    </p>
                </>
            )}
        </div>
    );
};

export default UserConfirmPage;
