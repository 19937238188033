import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Space, Alert, Radio, Spin, Checkbox, message } from "antd";
import api from "../../services/Api";
import { AuthContext } from "../../context/AuthContext";

const ProfileForm = ({ onSaveSuccess }) => {
    const { isAuthenticated, user } = useContext(AuthContext);
    const [form] = Form.useForm();
    const [showCompanyFields, setShowCompanyFields] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        // Update form fields with the latest user data whenever `user` changes
        form.setFieldsValue(user);
        setShowCompanyFields(user?.type === "company");
    }, [user, form]);

    const onFinish = async (values) => {
        setLoading(true);
        
        try {
            await api.saveUser(values);
            setError(false);
            onSaveSuccess(); // Update the parent component's user state
            message.success("Tiedot tallennettu");
        } catch (error) {
            setError("Virhe tietojen tallentamisessa. " + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const onUserTypeChange = (e) => {
        setShowCompanyFields(e.target.value === "company");
    };

    return (
        <>
            {error && <Alert showIcon className="mb-5" message={error} type="error" />}
            {loading ? (
                <Spin />
            ) : (
                <Form
                    form={form}
                    name="profile"
                    onFinish={onFinish}
                    layout="horizontal"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={user}
                >
                    <Form.Item
                        label="Etunimi"
                        name="firstname"
                        rules={[{ required: true, message: "Etunimi on pakollinen." }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Sukunimi"
                        name="lastname"
                        rules={[{ required: true, message: "Sukunimi on pakollinen." }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Sähköposti"
                        name="email"
                        rules={[
                            { required: true, message: "Sähköpostiosoite on pakollinen." },
                            { type: "email", message: "Tarkista sähköpostiosoitteen muoto." },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Puhelinnumero"
                        name="phonenumber"
                        rules={[{ required: true, message: "Puhelinnumero on pakollinen." }]}
                    >
                        <Input />
                    </Form.Item>

                    {!isAuthenticated && (
                        <>
                            <Form.Item
                                label="Salasana"
                                name="password"
                                rules={[{ required: true, message: "Syötä salasana!" }]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label="Vahvista salasana"
                                name="confirm"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    { required: true, message: "Vahvista salasana" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("The two passwords that you entered do not match!")
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item
                        label="Käyttäjätyyppi"
                        name="type"
                        rules={[{ required: true, message: "Valitse käyttäjätilin tyyppi" }]}
                    >
                        <Radio.Group onChange={onUserTypeChange} buttonStyle="solid">
                            <Radio.Button value="person">Yksityishenkilö</Radio.Button>
                            <Radio.Button value="company">Yritys</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    {showCompanyFields && (
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Form.Item
                                label="Yrityksen nimi"
                                name="companyName"
                                rules={[{ required: true, message: "Yrityksen nimi on pakollinen." }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Y-tunnus"
                                name="businessId"
                                rules={[{ required: true, message: "Yrityksen Y-tunnus on pakollinen." }]}
                            >
                                <Input />
                            </Form.Item>
                        </Space>
                    )}

                    <div className="mb-5">
                        Mikäli haluat ilmoituksia esim. päättyneistä huutokaupoista tai ylitetyistä tarjouksistasi
                        salli haluamasi viestintäkanavat.
                    </div>

                    <Form.Item
                        label="Sähköposti"
                        name="emailAllowed"
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>

                    <Form.Item
                        label="Tekstiviesti"
                        name="smsAllowed"
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            {isAuthenticated ? "Tallenna" : "Rekisteröidy"}
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </>
    );
};

export default ProfileForm;
