import React, { useState, useContext } from "react";
import { Spin, Button, Form, Input, Checkbox, Alert } from "antd";
import { AuthContext } from "../../context/AuthContext";
import api from "../../services/Api";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ onLoginSuccess, onForgotPassword }) => {
    const { login } = useContext(AuthContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        setLoading(true);

        try {
            const response = await api.login(values);

            if (response.status === 'error') {
                setLoading(false);
                setErrors(response.message);
            } else {
                login(response.token, response.user, values._remember_me);
                setLoading(false);
                navigate("/");
                onLoginSuccess();
            }
        } catch (err) {
            setLoading(false);

            if (err.response && err.response.data && err.response.data.message) {
                setErrors(err.response.data.message);
            } else {
                setErrors("Odottamaton virhe. Yritä uudelleen.");
            }
        }
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{ email: "", password: "", _remember_me: false }}
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
        >
            {errors && <Alert message={errors} type="error" showIcon className="mb-5" />}

            {loading ? (
                <div className="flex items-center justify-center min-h-32">
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <Form.Item
                        label="Sähköposti"
                        name="email"
                        rules={[
                            { required: true, message: "Sähköposti on pakollinen" },
                            { type: "email", message: "Anna kelvollinen sähköpostiosoite" },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Salasana"
                        name="password"
                        rules={[{ required: true, message: "Salasana on pakollinen" }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item name="_remember_me" valuePropName="checked">
                        <Checkbox>Muista kirjautuminen</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block id="submit-button">
                            Kirjaudu
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <div className="underline cursor-pointer" onClick={onForgotPassword}>
                            Unohditko salasanasi?
                        </div>{" "}
                        {/* Trigger tab change */}
                    </Form.Item>
                </>
            )}
        </Form>
    );
};

export default LoginForm;
