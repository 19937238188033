import React, { useEffect, useState } from "react";

import api from "../../services/Api";
import { Modal, Popconfirm, Table } from "antd";

const UserModal = ({ user, open, onClose }) => {
    if (!user) {
        return null;
    }

    return (
        <Modal title="Käyttäjän tiedot" open={open} footer={null} onCancel={onClose}>
            <h1>{user.name}</h1>

            <div className="grid grid-cols-2 gap-4">
                <div className="font-bold">Sähköposti</div>{" "}
                <div>
                    <a href={`mailto:${user.email}`} className="text-blue-700 underline">
                        {user.email}
                    </a>
                </div>
                <div className="font-bold">Puhelinnumero</div> <div>{user.phonenumber}</div>
                <div className="font-bold">Osoite</div> <div>{user.address}</div>
                <div className="font-bold">Postinumero</div> <div>{user.postalCode}</div>
                <div className="font-bold">Kaupunki</div> <div>{user.city}</div>
                <div className="font-bold">Maa</div> <div>{user.country}</div>
            </div>

            {user.company && (
                <div>
                    <h2>Yritys</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="font-bold">Yrityksen nimi</div> <div>{user.company.name}</div>
                        <div className="font-bold">Y-tunnus</div> <div>{user.company.businessId}</div>
                    </div>
                </div>
            )}

            {/* <div>
                <h2>Actions</h2>
                <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => {}}>
                    <button className="btn btn-red">Delete</button>
                </Popconfirm>
            </div> */}
        </Modal>
    );
};

export default UserModal;
