import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../services/Api";
import { Tabs } from "antd";
import UserAuctionList from "../../components/UserAuctionList";

const UserAuctions = () => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("published");

    useEffect(() => {
        setLoading(true);

        const fetchAuctions = async () => {
            try {
                const data = await api.getUserAuctions(status);
                setAuctions(data);
            } catch (error) {
                console.error("Error fetching auctions:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAuctions();
    }, [status]);

    const handleTabChange = (key) => {
        setStatus(key);
    };

    const items = [
        { key: "published", label: "Julkaistut", children: <UserAuctionList loading={loading} auctions={auctions} /> },
        { key: "draft", label: "Luonnokset", children: <UserAuctionList loading={loading} auctions={auctions} /> },
        { key: "sold", label: "Myydyt", children: <UserAuctionList loading={loading} auctions={auctions} /> },
        { key: "expired", label: "Vanhentuneet", children: <UserAuctionList loading={loading} auctions={auctions} /> },
        { key: "cancelled", label: "Perutut", children: <UserAuctionList loading={loading} auctions={auctions} /> },
    ];

    return (
        <>
            <Link to="/oma-sivu/ilmoitukset/uusi">
                <div className="text-white bg-green-600 px-5 py-2 rounded hover:bg-green-500 hover:text-white transition-colors duration-200 inline-block float-right">
                    Lisää uusi ilmoitus
                </div>
            </Link>

            <h1 className="text-2xl mb-5">Ilmoitukset</h1>

            <Tabs defaultActiveKey="published" onChange={handleTabChange} items={items} />
        </>
    );
};

export default UserAuctions;
