import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Spin } from "antd";

import api from "../../services/Api";

const Payment = () => {
    const { uuid, discountCode } = useParams();
    const [paymentLink, setPaymentLink] = useState(null);

    const createPayment = async () => {
        try {
            const response = await api.createPayment(uuid, discountCode);

            setPaymentLink(response.href);
            document.location.href = response.href;
        } catch (error) {
            console.error("Error during payment processing: ", error);
        }
    };

    useEffect(() => {
        createPayment();
    }, []);

    return (
        <div>
            <div className="text-3xl w-full flex justify-center flex-col items-center">
                <Spin className="mr-2 mb-5" size="large" />
                <div>Siirrytään maksupalveluun...</div>
                <div className="text-2xl underline text-blue-600 mt-5">
                    Jos siirto ei onnistu, klikkaa <Link to={paymentLink}>tästä</Link>
                </div>
            </div>
        </div>
    );
};

export default Payment;
