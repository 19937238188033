import React, { useState, useEffect } from "react";
import api from "../../services/Api";
import { Button, Modal, Progress, Spin, Checkbox } from "antd";

const AdminActions = () => {
    const [filesSummary, setFilesSummary] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
    const [regenerating, setRegenerating] = useState(false);
    const [regenerateData, setRegenerateData] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);

    useEffect(() => {
        fetchFilesSummary();
        const interval = setInterval(() => {
            fetchFilesSummary();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const fetchFilesSummary = async () => {
        try {
            const response = await api.getFilesSummary();
            setFilesSummary(response);
        } catch (error) {
            console.error("Error fetching file summary:", error);
        }
    };

    const handleSizeChange = (checkedValues) => {
        setSelectedSizes(checkedValues);
    };

    const generateMissingFiles = async () => {
        setLoading(true);

        try {
            await api.generateMissingFiles();
            fetchFilesSummary();
        } catch (error) {
            console.error("Error generating missing files:", error);
        } finally {
            setLoading(false);
        }
    };

    const regenerateImages = async () => {
        setRegenerating(true);
        setIsRegenerateModalOpen(true);

        try {
            await api.regenerateImages(selectedSizes);
            setRegenerating(false);
        } catch (error) {
            console.error("Error regenerating images:", error);
        } finally {
            setRegenerating(false);
        }
    };

    useEffect(() => {
        if (regenerating) {
            const interval = setInterval(async () => {
                try {
                    const response = await api.getRegenerationProgress();
                    setRegenerateData(response);
                } catch (error) {
                    console.error("Error fetching progress:", error);
                }
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setRegenerateData([]);
            setIsRegenerateModalOpen(false);
        }
    }, [regenerating]);

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Toiminnot</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="bg-white p-5 rounded shadow">
                    <h2 className="text-xl mb-2">Tiedostot</h2>

                    <table className="table-auto">
                        <tbody>
                            <tr>
                                <td width="150">Tiedostot</td>
                                <td>{filesSummary?.files}</td>
                            </tr>
                            <tr>
                                <td>Löytyy</td>
                                <td>{filesSummary?.files_exists}</td>
                            </tr>
                            <tr>
                                <td>Kuvat</td>
                                <td>{filesSummary?.images}</td>
                            </tr>
                            <tr>
                                <td>Löytyy</td>
                                <td>{filesSummary?.image_exists}</td>
                            </tr>
                            <tr>
                                <td>Thumbnail</td>
                                <td>{filesSummary?.image_thumbnail_exists}</td>
                            </tr>
                            <tr>
                                <td>Medium</td>
                                <td>{filesSummary?.image_medium_exists}</td>
                            </tr>
                            <tr>
                                <td>Large</td>
                                <td>{filesSummary?.image_large_exists}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mt-5">
                        <Checkbox.Group
                            options={[
                                { label: "Thumbnail", value: "thumbnail" },
                                { label: "Medium", value: "medium" },
                                { label: "Large", value: "large" }
                            ]}
                            onChange={handleSizeChange}
                        />
                    </div>
                    <div className="flex justify-end mt-5">
                        <Button type="primary" className="mr-5" onClick={regenerateImages} disabled={loading || selectedSizes.length === 0}>
                            {regenerating ? <Spin /> : "Päivitä kuvatiedostot"}
                        </Button>
                        <Button type="primary" onClick={generateMissingFiles} disabled={loading}>
                            {loading ? <Spin /> : "Luo puuttuvat"}
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                title="Regenerating Images"
                open={isRegenerateModalOpen}
                footer={null}
                onCancel={() => setIsRegenerateModalOpen(false)}
            >
                <div className="text-center">{regenerateData.count} / {regenerateData.total}</div>
                <Progress percent={regenerateData.progress} />
            </Modal>
        </div>
    );
};

export default AdminActions;
