import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Spin } from "antd";

const ProtectedRoute = ({ requiredRole }) => {
    const { isAuthenticated, user, setReferer, hasRole, loading } = useContext(AuthContext);
    const location = useLocation();

    if (loading) {
        return <Spin />;
    }

    if (!isAuthenticated) {
        setReferer(location.pathname);
        return <Navigate to="/" replace />;
    }

    // If user doesn't have correct user level redirect user to 
    if (requiredRole && !hasRole(user.roles, requiredRole)) {
        return <Navigate to="/" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
