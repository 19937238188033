import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../services/Api";

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

    const fetchUnreadMessagesCount = async () => {
        try {
            const response = await api.getUnreadMessagesCount();
            setUnreadMessagesCount(response.unreadMessagesCount);
        } catch (error) {
            console.error("Error fetching unread messages count:", error);
        }
    };

    // Fetch unread message count on component mount
    useEffect(() => {
        fetchUnreadMessagesCount();
    }, []);

    const updateUnreadMessagesCount = (newCount) => {
        setUnreadMessagesCount(newCount);
    };

    return (
        <ChatContext.Provider
            value={{
                unreadMessagesCount,
                updateUnreadMessagesCount,
                fetchUnreadMessagesCount,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};
