import { Form, Input, Button, Spin, message } from "antd";
import React, { useState } from "react";
import api from "../services/Api";

const ContactPage = () => {
    const [sending, setSending] = useState(false);
    const [form] = Form.useForm();

    const sendMessage = async (formData) => {
        setSending(true);

        try {
            await api.sendContactMessage(formData);
            message.success("Viesti lähetetty onnistuneesti!");
            form.resetFields(); // Clear the form after successful submission
        } catch (error) {
            message.error("Virhe lähetettäessä viestiä, yritä uudelleen.");
        } finally {
            setSending(false);
        }
    };

    const handleFinish = (values) => {
        sendMessage(values); // Send the gathered form data
    };

    return (
        <div className={"container m-auto max-w-screen-2xl md:px-0 text-base mt-5"}>
            <h1>Yhteystiedot</h1>

            <Form form={form} layout="vertical" onFinish={handleFinish} className="md:w-1/2">
                <Form.Item 
                    label="Nimi" 
                    name="name" 
                    rules={[{ required: true, message: 'Anna nimesi' }]}
                >
                    <Input className="p-2 border border-gray-300 rounded w-full" />
                </Form.Item>

                <Form.Item 
                    label="Sähköposti" 
                    name="email" 
                    rules={[
                        { required: true, message: 'Anna sähköpostiosoitteesi' }, 
                        { type: 'email', message: 'Syötä kelvollinen sähköpostiosoite' }
                    ]}
                >
                    <Input type="email" className="p-2 border border-gray-300 rounded w-full" />
                </Form.Item>

                <Form.Item 
                    label="Viesti" 
                    name="message" 
                    rules={[{ required: true, message: 'Kirjoita viesti' }]}
                >
                    <Input.TextArea rows={7} className="p-2 border border-gray-300 rounded w-full" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={sending}>
                        {sending ? <Spin /> : "Lähetä"}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ContactPage;
