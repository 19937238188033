import React, { useState, useEffect } from 'react';
import { Button, Modal, Spin } from 'antd';
import api from '../../services/Api';

const SettingsModal = ({ settingName, title, open, onClose, className }) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSettingContent = async () => {
            try {
                const response = await api.getSetting(settingName);
                setContent(response.value);
            } catch (error) {
                console.error('Error fetching setting:', error);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchSettingContent();
        }
    }, [settingName, open]);

    return (
        <Modal open={open} onCancel={onClose} footer={null} title={title} width={800}>
            {loading ? <Spin /> : <div dangerouslySetInnerHTML={{ __html: content }} />}
            <div className="text-center mt-5">
                <Button onClick={onClose} type='primary'>Sulje</Button>
            </div>
        </Modal>
    );
};

export default SettingsModal;
