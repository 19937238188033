import React from 'react';


import backgroundImage from "../assets/images/bg-404.webp";

const NotFound = () => {
    const style = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
    };

    return (
        <div style={style} className="flex flex-col items-center justify-center h-full border border-black">
            <h1 className="text-7xl font-bold text-primary">404</h1>
            <p className="text-3xl text-primary">
                Valitettavasti eksyit sivulle, jota ei löydy.
            </p>
            <p className="text-2xl">
                <a href="/" className="text-primary underline">Palaa etusivulle</a>
            </p>
        </div>
    );
}

export default NotFound;