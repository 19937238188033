import React from "react";
import { Form, Button, Upload, Tooltip, Spin } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";

import api from "../../services/Api";

const PropertyFormImages = ({ initialValues, images, setImages }) => {
    const onSortEnd = (oldIndex, newIndex) => {
        const reorderedImages = arrayMove([...images], oldIndex, newIndex);

        const updatedImages = reorderedImages.map((image, index) => ({
            ...image,
            sort_num: index + 1,
        }));

        setImages(updatedImages);
    };

    const uploadProps = {
        name: "file",
        multiple: true,
        showUploadList: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            const placeholderId = `temp-${file.uid}`;
            const placeholderImage = {
                id: placeholderId,
                urls: { thumbnail: URL.createObjectURL(file) },
                sort_num: images.length + 1,
                placeholder: true,
            };

            setImages((prevImages) => [...prevImages, placeholderImage]);

            const params = {
                propertyId: initialValues.id,
                category: "public",
            };

            try {
                const response = await api.uploadFile(file, params);
                onSuccess(response);

                setImages((prevImages) => prevImages.map((img) => (img.id === placeholderId ? response.file : img)));
            } catch (error) {
                onError(error);

                setImages((prevImages) => prevImages.filter((img) => img.id !== placeholderId));
            }
        },
    };

    return (
        <>
            <Form.Item label="Kohteen kuvat">
                <Upload {...uploadProps}>
                    <Button type="primary" icon={<UploadOutlined />}>
                        Lataa kuvia
                    </Button>
                </Upload>
                <div>
                    <SortableList
                        onSortEnd={onSortEnd}
                        className="list flex flex-wrap gap-5 mt-5 user-select-none"
                        draggedItemClassName="dragged"
                    >
                        {images?.map((image) => (
                            <SortableItem key={image.id}>
                                <div
                                    style={{
                                        width: "175px",
                                        height: "125px",
                                        overflow: "hidden",
                                        border: "solid 1px #ddd",
                                        position: "relative",
                                        userSelect: "none",
                                        cursor: "grab",
                                        opacity: image.placeholder ? 0.50 : 1, // Set opacity for placeholder
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundImage: `url(${image.urls.thumbnail})`,
                                    }}
                                >
                                    {image.placeholder && (
                                        <div className="flex items-center justify-center text-black w-full h-full">
                                            <Spin size="large" indicator={<LoadingOutlined className="text-white" spin />} />
                                        </div>
                                    )}
                                    {!image.placeholder && (
                                        <Tooltip title="Poista kuva">
                                            <div
                                                onClick={() => setImages(images.filter((img) => img.id !== image.id))}
                                                className="absolute top-1 right-1 bg-red-600 text-white p-1 rounded cursor-pointer"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="size-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18 18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            </SortableItem>
                        ))}
                    </SortableList>
                </div>
            </Form.Item>
        </>
    );
};

export default PropertyFormImages;
