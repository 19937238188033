import React, { useState } from "react";
import { Modal, Spin } from "antd";
import api from "../../services/Api";
import { useNavigate } from "react-router-dom";

const ServiceModal = ({ open, onClose }) => {
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const createOrder = async () => {
        setLoading(true);

        const orderData = {
            product: "Palvelupaketti 1",
            price: 89.0,
        };

        try {
            const response = await api.createOrder(orderData);

            navigate(`/pay/${response.uuid}`);
        } catch (error) {
            console.error("Error creating order:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onCancel={onClose}
            title="Tilaa ilmoituksen luonti"
            okText="Siirry maksamaan"
            cancelText="Peruuta"
            onOk={createOrder}
        >
            {loading ? (
                <Spin />
            ) : (
                <>
                    <p>Olet tilaamassa tuotetta:</p>
                    <p className="font-bold text-lg">Palvelupaketti 1 - 89,00€</p>
                    <p>Tilattuasi paketin otamme sinuun yhteyttä mahdollisimman pian.</p>
                </>
            )}
        </Modal>
    );
};

export default ServiceModal;
