import React from "react";

import logoWhite from "../../assets/images/lomahuuto-fi-logo-white-1024.png";

const Footer = () => {
    return (
        <footer className="text-center p-8 bg-primary-700">
            <div><img src={logoWhite} alt="Lomahuuto.fi" className="w-64 m-auto" /></div>
            <div className="text-primary-100 font-bold text-sm mt-5">Copyright &copy; 2024 Lomahuuto.fi. All rights reserved.</div>
        </footer>
    );
}

export default Footer;