import React from "react";
import Hero from "../common/Hero";
import Footer from "../common/Footer";

const FrontPageLayout = ({ children }) => {
    return (
        <>
            <div className="flex flex-col">
                <Hero />
                <main className="flex-1">{children}</main>
                <Footer />
            </div>
        </>
    );
};

export default FrontPageLayout;
