import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "/api";

axios.defaults.headers.post["Content-Type"] = "application/json";

const api = {
    saveUser: async (userData) => {
        const token = localStorage.getItem("token");

        try {
            const headers = token ? { Authorization: `Bearer ${token}` } : {};

            const response = await axios.post(`${API_URL}/user/save`, userData, { headers });

            return response.data;
        } catch (error) {
            console.error("Error saving user:", error);
            throw error;
        }
    },

    confirmUser: async (token) => {
        try {
            const response = await axios.get(`${API_URL}/user/confirm/${token}`);

            return response.data;
        } catch (error) {
            console.error("Error confirming user:", error);
            throw error;
        }
    },

    getBadges: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/badges`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching badge count:", error);
            throw error;
        }
    },

    login: async (userData) => {
        try {
            const response = await axios.post(`${API_URL}/login_check`, userData);

            return response.data;
        } catch (error) {
            console.error("Error logging in:", error);
            throw error;
        }
    },

    getAuctionBids: async (number) => {
        const token = localStorage.getItem("token");

        try {
            const headers = token ? { Authorization: `Bearer ${token}` } : {};

            const response = await axios.get(`${API_URL}/auction/${number}/bids`, {
                headers,
            });

            return response.data;
        } catch (error) {
            console.error(`Error fetching bids for auction with number ${number}:`, error);
            throw error;
        }
    },

    getAuctionOffers: async (number) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/auction/offers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error fetching offers for auction with number ${number}:`, error);
            throw error;
        }
    },

    acceptOffer: async (offerId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/auction/offer/${offerId}/accept`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error approving offer with ID ${offerId}:`, error);
            throw error;
        }
    },

    rejectOffer: async (offerId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/auction/offer/${offerId}/reject`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error rejecting offer with ID ${offerId}:`, error);
            throw error;
        }
    },

    getUser: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    },

    checkSession: async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(`${API_URL}/session`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error checking session:", error);
            throw error;
        }
    },

    createAuction: async (propertyData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/auction/create`, propertyData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error creating property:", error);
            throw error;
        }
    },

    getUserAuction: async (auctionUuid) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/auction/${auctionUuid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error fetching auction with ID ${auctionUuid}:`, error);
            throw error;
        }
    },

    getAuction: async (number) => {
        try {
            const token = localStorage.getItem("token");
            const headers = {};

            if (token) {
                headers.Authorization = `Bearer ${token}`;
            }

            const response = await axios.get(`${API_URL}/auction/${number}`, { headers });

            return response.data;
        } catch (error) {
            console.error(`Error fetching auction with ID ${number}:`, error);
            throw error;
        }
    },

    getAuctions: async (filters, limit = 0) => {
        try {
            const token = localStorage.getItem("token");
            const headers = {};

            if (token) {
                headers.Authorization = `Bearer ${token}`;
            }

            const response = await axios.get(`${API_URL}/auctions?limit=${limit}&filters=${JSON.stringify(filters)}`, {
                headers,
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching auctions:", error);
            throw error;
        }
    },

    getUserAuctions: async (status, limit = 0) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/auctions`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { status, limit },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching auctions:", error);
            throw error;
        }
    },

    getAdminAuctions: async (status, type, searchTerm, page, pageSize) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/admin/auctions`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { status, type, searchTerm, page, pageSize },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching auctions:", error);
            throw error;
        }
    },

    saveAuction: async (auctionUuid, auctionData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/auction/${auctionUuid}/save`, auctionData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error saving auction with ID ${auctionUuid}:`, error);
            throw error;
        }
    },

    publishAuction: async (auctionUuid) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/auction/${auctionUuid}/publish`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error publishing auction with ID ${auctionUuid}:`, error);
            throw error;
        }
    },

    uploadFile: async (file, params) => {
        const token = localStorage.getItem("token");
        const formData = new FormData();

        formData.append("file", file);
        formData.append("uuid", params.auctionId || "");
        formData.append("folderId", params.folderId || "");
        formData.append("propertyId", params.propertyId || "");
        formData.append("category", params.category || "");

        try {
            const response = await axios.post(`${API_URL}/user/files/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
        }
    },

    deleteFile: async (fileId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.delete(`${API_URL}/user/files/delete/${fileId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error deleting file:", error);
            throw error;
        }
    },

    getUserImages: async (folderId, page, pageSize) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(
                `${API_URL}/user/images?folder=${folderId}&page=${page}&pageSize=${pageSize}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error fetching images:", error);
            throw error;
        }
    },

    getGalleryFolders: async (folderId = null) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/gallery/folders`, {
                params: { folderId },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching folders:", error);
            throw error;
        }
    },

    deleteImage: async (id) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.delete(`${API_URL}/user/image/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error deleting image:", error);
            throw error;
        }
    },

    deleteFolder: async (id) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.delete(`${API_URL}/user/files/folder/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error deleting folder:", error);
            throw error;
        }
    },

    createFolder: async (name, parentId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/user/files/folder/create`,
                { name, parentId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Error creating folder:", error);
            throw error;
        }
    },

    getFolder: async (folderId = null, page, pageSize) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/files/folder`, {
                params: { folderId, page, pageSize },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching folder:", error);
            throw error;
        }
    },

    getProperties: async () => {
        try {
            const response = await axios.get(`${API_URL}/properties`);

            return response.data;
        } catch (error) {
            console.error("Error fetching properties:", error);
            throw error;
        }
    },

    getUserProperties: async (page, pageSize, searchTerm, area) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/properties`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { page, pageSize, searchTerm, area },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching properties:", error);
            throw error;
        }
    },

    getUserProperty: async (propertyId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/property/${propertyId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error fetching property with ID ${propertyId}:`, error);
            throw error;
        }
    },

    getProperty: async (propertyId) => {
        try {
            const response = await axios.get(`${API_URL}/property/${propertyId}`);

            return response.data;
        } catch (error) {
            console.error(`Error fetching property with ID ${propertyId}:`, error);
            throw error;
        }
    },

    getAvailableAreas: async () => {
        try {
            const response = await axios.get(`${API_URL}/areas`);

            return response.data;
        } catch (error) {
            console.error("Error fetching areas:", error);
            throw error;
        }
    },

    getAvailableAreasAuctions: async () => {
        try {
            const response = await axios.get(`${API_URL}/areas-auctions`);

            return response.data;
        } catch (error) {
            console.error("Error fetching areas:", error);
            throw error;
        }
    },

    getPropertyImages: async (propertyId, page, pageSize) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/property/${propertyId}/images`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { page, pageSize },
            });

            return response.data;
        } catch (error) {
            console.error(`Error fetching images for property with ID ${propertyId}:`, error);
            throw error;
        }
    },

    createUserProperty: async (propertyData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/property/create`, propertyData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error creating property:", error);
            throw error;
        }
    },

    saveUserProperty: async (propertyId, propertyData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/property/${propertyId}/save`, propertyData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error saving property with ID ${propertyId}:`, error);
            throw error;
        }
    },

    submitBid: async (auctionId, bidData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/auction/${auctionId}/bid`, bidData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error submitting bid:", error);
            throw error;
        }
    },

    submitOffer: async (auctionId, offerData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/auction/${auctionId}/offer`, offerData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error submitting offer:", error);
            throw error;
        }
    },

    sendChatMessage: async (messageData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/chat/message/send`, messageData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error sending message:", error);
            throw error;
        }
    },

    getUserChats: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/chats`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching chats:", error);
            throw error;
        }
    },

    getChatMessages: async (chatUuid) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/chat/${chatUuid}/messages`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching messages:", error);
            throw error;
        }
    },

    getSettings: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/admin/settings`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching settings:", error);
            throw error;
        }
    },

    saveSettings: async (settingsData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/admin/settings/save`, settingsData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error saving settings:", error);
            throw error;
        }
    },

    getSetting: async (settingName) => {
        try {
            const response = await axios.get(`${API_URL}/settings/${settingName}`);

            return response.data;
        } catch (error) {
            console.error("Error fetching setting:", error);
            throw error;
        }
    },

    deleteSetting: async (settingId) => {
        const token = localStorage.getItem("token");

        try {
            await axios.delete(`${API_URL}/admin/settings/delete/${settingId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (error) {
            console.error("Error deleting setting:", error);
            throw error;
        }
    },

    setAuctionExpired: async (auctionUuid) => {
        try {
            const response = await axios.get(`${API_URL}/auction/${auctionUuid}/expire`);

            return response.data;
        } catch (error) {
            console.error("Error expiring auction:", error);
            throw error;
        }
    },

    getApartments: async (propertyId) => {
        try {
            const response = await axios.get(`${API_URL}/property/${propertyId}/apartments`);

            return response.data;
        } catch (error) {
            console.error("Error fetching apartments:", error);
            throw error;
        }
    },

    updateApartment: async (apartmentId, apartmentData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/apartment/${apartmentId}/save`, apartmentData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error saving apartment with ID ${apartmentId}:`, error);
            throw error;
        }
    },

    deleteApartment: async (apartmentId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.delete(`${API_URL}/user/apartment/${apartmentId}/delete`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error(`Error deleting apartment with ID ${apartmentId}:`, error);
            throw error;
        }
    },

    addApartment: async (propertyId, newApartment) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/user/apartment/create`,
                { ...newApartment, propertyId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error adding apartment:", error);
            throw error;
        }
    },

    getCities: async () => {
        try {
            const response = await axios.get(`${API_URL}/cities`);

            return response.data;
        } catch (error) {
            console.error("Error fetching cities:", error);
            throw error;
        }
    },

    getFilesSummary: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/god/files/summary`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching file summary:", error);
            throw error;
        }
    },

    generateMissingFiles: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/god/files/generateMissing`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error generating missing files:", error);
            throw error;
        }
    },

    getUsers: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/users`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error;
        }
    },

    getRegenerationProgress: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/god/files/regenerationProgress`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching progress:", error);
            throw error;
        }
    },

    regenerateImages: async (sizes) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/god/files/regenerateImages`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    sizes: sizes.join(","), // Pass sizes as a comma-separated string
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error regenerating images:", error);
            throw error;
        }
    },

    toggleFavorite: async (auctionNumber) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/auction/${auctionNumber}/favorite`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error toggling favorite:", error);
            throw error;
        }
    },

    getFavoriteAuctions: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/favorites`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching favorite auctions:", error);
            throw error;
        }
    },

    toggleFeatured: async (auctionId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/superadmin/auction/${auctionId}/toggleFeatured`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error toggling featured:", error);
            throw error;
        }
    },

    getFeaturedAuctions: async () => {
        try {
            const response = await axios.get(`${API_URL}/auctions/featured`);

            return response.data;
        } catch (error) {
            console.error("Error fetching featured auctions:", error);
            throw error;
        }
    },

    sendContactMessage: async (messageData) => {
        try {
            const response = await axios.post(`${API_URL}/sendContactMessage`, messageData);

            return response.data;
        } catch (error) {
            console.error("Error sending contact message:", error);
            throw error;
        }
    },

    exchangeAuthCodeForToken: async (code, state) => {
        try {
            const response = await axios.post(`${API_URL}/auth/exchange`, { code, state });

            return response.data;
        } catch (error) {
            console.error("Error exchanging auth code for token:", error);
            throw error;
        }
    },

    decodeIdToken: async (token) => {
        try {
            const response = await axios.post(`${API_URL}/auth/decode`, { token });

            return response.data;
        } catch (error) {
            console.error("Error decoding token:", error);
            throw error;
        }
    },

    getAuthData: async (authorizationCode) => {
        try {
            const response = await axios.post(`${API_URL}/auth/data`, { authorizationCode });

            return response.data;
        } catch (error) {
            console.error("Error fetching auth data:", error);
            throw error;
        }
    },

    getUserInfo: async (accessToken) => {
        try {
            const response = await axios.post(`${API_URL}/auth/userinfo`, { accessToken });

            return response.data;
        } catch (error) {
            console.error("Error fetching user info:", error);
            throw error;
        }
    },

    getContract: async (auctionUuid) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/auction/${auctionUuid}/contract`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching contract:", error);
            throw error;
        }
    },

    saveContract: async (auctionUuid, contractData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/auction/${auctionUuid}/contract/save`, contractData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error saving contract:", error);
            throw error;
        }
    },

    requestPasswordReset: async (email) => {
        try {
            const response = await axios.post(`${API_URL}/password/reset`, { email });

            return response.data;
        } catch (error) {
            console.error("Error requesting password reset:", error);
            throw error;
        }
    },

    resetPassword: async (data) => {
        try {
            const response = await axios.post(`${API_URL}/password/reset/confirm`, data);

            return response.data;
        } catch (error) {
            console.error("Error resetting password:", error);
            throw error;
        }
    },

    changePassword: async (data) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/password/change`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error changing password:", error);
            throw error;
        }
    },

    fetchPrice: async (auctionUuid, discountCode) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/user/auction/price`,
                { auctionUuid, discountCode },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error fetching prices:", error);
            throw error;
        }
    },

    createPayment: async (auctionUuid, discountCode) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/payment/create`,
                { auctionUuid, discountCode },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error creating payment:", error);
            throw error;
        }
    },

    confirmPayment: async (transactionId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/payment/confirm`,
                { transactionId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error confirming payment:", error);
            throw error;
        }
    },

    createFeaturePayment: async (auctionUuid, months) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/featured/payment/create`,
                { auctionUuid, months },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error creating payment:", error);
            throw error;
        }
    },

    confirmFeaturePayment: async (transactionId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/featured/payment/confirm`,
                { transactionId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error confirming payment:", error);
            throw error;
        }
    },

    getDiscountCodes: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/discount-codes`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching discount codes:", error);
            throw error;
        }
    },

    saveDiscountCodes: async (discountCodes) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/discount-codes/save`, discountCodes, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error saving discount codes:", error);
            throw error;
        }
    },

    deleteDiscountCode: async (discountCodeId) => {
        const token = localStorage.getItem("token");

        try {
            await axios.delete(`${API_URL}/discount-codes/delete/${discountCodeId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (error) {
            console.error("Error deleting discount code:", error);
            throw error;
        }
    },

    getUnreadMessagesCount: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/user/chat/unreadMessagesCount`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching unread messages count:", error);
            throw error;
        }
    },

    sendEmail: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/sendEmail`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error sending email:", error);
            throw error;
        }
    },

    createContractPdf: async (auctionUuid) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/auction/${auctionUuid}/contract/createPdf`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error creating contract PDF:", error);
            throw error;
        }
    },

    getContactMessages: async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.get(`${API_URL}/contactMessages`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error fetching contact messages:", error);
            throw error;
        }
    },

    createOrder: async (orderData) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(`${API_URL}/user/order/create`, orderData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error creating order:", error);
            throw error;
        }
    },

    createOrderPayment: async (orderUuid) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/payment/order/create`,
                { orderUuid },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error creating order payment:", error);
            throw error;
        }
    },

    userAction: async (userId, action) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/admin/user/action`,
                { userId, action },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error toggling email confirmed:", error);
            throw error;
        }
    },

    transferAuctionToUser: async (auctionUuid, userId) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                `${API_URL}/admin/auction/transfer`,
                { auctionUuid, userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Error transferring auction:", error);
            throw error;
        }
    },

    sendContactRequest: async (auctionUuid) => {
        const token = localStorage.getItem("token");

        const data = {
            'auctionUuid': auctionUuid,
        };

        try {
            const response = await axios.post(`${API_URL}/auction/contactRequest`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error sending contact request:", error);
            throw error;
        }
    },
};

export default api;
