import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import ProfileForm from "../components/forms/ProfileForm";
import LoginForm from "../components/forms/LoginForm";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Login = () => {
    const [registered, setRegistered] = useState(false);
    const { isAuthenticated, setReferer } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const redirectUrl = location.state?.from || null;

    useEffect(() => {
        if (isAuthenticated && redirectUrl) {
            navigate(redirectUrl, { replace: true });
        } else {
            setReferer(redirectUrl);
        }
    }, [isAuthenticated, navigate, redirectUrl, setReferer]);

    return (
        <div className="container max-w-screen-2xl m-auto text-lg p-5 md:px-0">
            <div className="bg-[#b3d2d4] p-5 md:p-10 md:flex gap-10 rounded">
                <div className="flex-1">
                    <h2 className="text-2xl font-bold mb-5">Rekisteröidy</h2>

                    {registered ? (
                        <div className="bg-green-100 border border-green-200 text-green-900 p-5 rounded mb-5">
                            <p>Rekisteröityminen onnistui!</p>
                            <p>
                                Saat pian sähköpostiisi vahvistusviestin, jossa on ohjeet tilisi vahvistamiseen.
                                Vahvistamisen jälkeen voit kirjautua sisään.
                            </p>
                            <p>
                                Jos et saa viestiä, tarkista roskapostikansio tai ota yhteyttä{" "}
                                <Link to={`/yhteystiedot`} className="underline text-blue-500">asiakaspalveluun</Link>.
                            </p>
                        </div>
                    ) : (
                        <ProfileForm onSaveSuccess={() => setRegistered(true)} />
                    )}
                </div>
                <div className="flex-1">
                    <h2 className="text-2xl font-bold mb-5">Kirjaudu sisään</h2>
                    <p className="mb-5">Jos sinulla on jo tunnus voit kirjautua sisään tästä.</p>
                    <LoginForm />
                </div>
            </div>
        </div>
    );
};

export default Login;
