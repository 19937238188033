import React, { useState, useEffect, useCallback, useContext } from "react";
import api from "../../../services/Api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button, message, Spin, Form, Radio, Modal, Checkbox, Input, ConfigProvider, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../context/AuthContext";
import Cookies from "js-cookie";

import PreviewModal from "../../modals/PreviewModal";
import SettingsModal from "../../modals/SettingsModal";
import PropertySelectModal from "../../modals/PropertySelectModal";

import AuctionFormProperty from "./AuctionFormProperty";
import AuctionFormMandator from "./AuctionFormMandator";
import AuctionFormSaleInfo from "./AuctionFormSaleInfo";
import AuctionFormLinks from "./AuctionFormLinks";

import FileUpload from "../FileUpload";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ServiceModal from "../../modals/ServiceModal";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Helsinki");

const URL =
    process.env.NODE_ENV === "development" ? "https://api-lomahuuto.localhost" : "https://api-lomahuuto.mkoskinen.net";

const AuctionForm = () => {
    const { user, hasRole } = useContext(AuthContext);
    const [auction, setAuction] = useState({
        auctionType: "",
        startPrice: null,
        minBid: null,
        minSalePrice: null,
        bids: [],
        status: "",
        property: null,
        propertyId: null,
        propertyWeek: null,
        propertyWeekStart: null,
        propertyAttributes: [],
        propertyFiles: [],
        starts: null,
        ends: null,
        description: "",
        saleTime: null,
        roomNumber: "",
        maintenanceCharge: null,
        images: [],
        files: [],
        links: [],
        mandator: {},
        hasMandator: false,
        discountCode: "",
    });
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [propertyModalOpen, setPropertyModalOpen] = useState(false);
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [form] = Form.useForm();
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(null);
    const [publishedModalOpen, setPublishedModalOpen] = useState(null);
    const [buyFeaturedModalOpen, setBuyFeaturedModalOpen] = useState(null);
    const [featuredUntil, setFeaturedUntil] = useState(null);
    const [featuredError, setFeaturedError] = useState(null);
    const [serviceModalOpen, setServiceModalOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [transferToUserId, setTransferToUserId] = useState(null);
    const [contactRequestSent, setContactRequestSent] = useState(false);
    const [showContactRequest, setShowContactRequest] = useState(true);

    useEffect(() => {
        const hideContactRequest = Cookies.get("hideContactRequest");

        if (auction.uuid && hideContactRequest === auction.uuid) {
            setShowContactRequest(false);
        }
    }, [auction.uuid]);

    const handleDoNotShow = () => {
        Cookies.set("hideContactRequest", auction.uuid, { expires: 365 }); // Cookie expires in 30 days
        setShowContactRequest(false);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.getUsers();
                setUsers(response);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        if (hasRole(user.roles, "ROLE_SUPERADMIN")) {
            fetchUsers();
        }
    }, []);

    const showTermsModal = () => {
        setTermsModalOpen(true);
    };

    const closeTermsModal = () => {
        setTermsModalOpen(false);
    };

    useEffect(() => {
        if (uuid && uuid !== "uusi") {
            setIsLoading(true);

            const fetchAuction = async () => {
                try {
                    const response = await api.getUserAuction(uuid);
                    setAuction(response);

                    if (response.mandator?.id) {
                        setAuction((prevState) => ({
                            ...prevState,
                            hasMandator: true,
                            mandator: {
                                id: response.mandator.id,
                                type: response.mandator.type,
                                name: response.mandator.name,
                                address: response.mandator.address,
                                phonenumber: response.mandator.phonenumber,
                                email: response.mandator.email,
                                businessId: response.mandator.businessId,
                            },
                        }));
                    }

                    form.setFieldsValue(response);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                }
            };
            fetchAuction();
        }
    }, [uuid]);

    const handleSelectAuctionType = async (event) => {
        const auctionType = event.target.value;

        setAuction((prevAuction) => ({
            ...prevAuction,
            auctionType: auctionType,
        }));

        if (uuid === "uusi") {
            try {
                const response = await api.createAuction({ auctionType: auctionType });
                navigate(`/oma-sivu/ilmoitukset/${response.uuid}`);
            } catch (error) {
                console.error("Error creating auction:", error);
            }
        }
    };

    const handleSave = async () => {
        setIsSaving(true);

        try {
            await api.saveAuction(uuid, {
                ...auction,
                starts: auction.starts
                    ? dayjs(auction.starts).tz("Europe/Helsinki").format("YYYY-MM-DD HH:mm:ss")
                    : null,
                ends: auction.ends ? dayjs(auction.ends).tz("Europe/Helsinki").format("YYYY-MM-DD HH:mm:ss") : null,
            });

            setIsSaving(false);

            message.success(auction.status === "draft" ? "Luonnos tallennettu" : "Ilmoitus tallennettu");
        } catch (error) {
            setIsSaving(false);
            message.error("Virhe ilmoituksen tallentamisessa");
        }
    };

    const handleFilesChange = (updatedFiles) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            files: updatedFiles,
        }));
    };

    const handleStatusChange = async (status) => {
        let modalTitle = "";
        let modalContent = "";
        let modalSuccess = "";
        let modalOkText = "";
        let response = null;

        if (status === "published") {
            await api.saveAuction(uuid, { ...auction, status: "draft" });

            // Fetch price directly and use it in the flow
            response = await api.fetchPrice(auction.uuid, auction.discountCode);

            modalTitle = "Julkaise ilmoitus";
            modalContent = (
                <div>
                    <p>Tämän ilmoituksen julkaiseminen maksaa</p>
                    <p className="text-lg">
                        <strong>{response.price}€</strong>
                        {response.originalPrice !== response.price && (
                            <span>
                                {" "}
                                (<span className="line-through text-red-600">{response.originalPrice}€</span>)
                            </span>
                        )}
                        .
                    </p>
                    <p>
                        {response.price !== "0.00" && <>Siirry maksamaan klikkaamalla "Jatka maksamaan" -painiketta.</>}
                    </p>
                </div>
            );

            modalSuccess = "Ilmoitus julkaistu";

            modalOkText = response.price !== "0.00" ? "Jatka maksamaan" : "Julkaise ilmoitus";

            if (!validateForm(form)) {
                return;
            }
        } else if (status === "sold") {
            modalTitle = "Merkitse myydyksi";
            modalContent = "Olet merkitsemässä kohdetta myydyksi. Oletko varma?";
            modalSuccess = "Kohde merkitty myydyksi";
            modalOkText = "Merkitse myydyksi";
        } else if (status === "cancelled") {
            modalTitle = "Lopeta julkaisu";
            modalContent = "Olet lopettamassa ilmoituksen julkaisua. Oletko varma?";
            modalSuccess = "Ilmoitus lopetettu";
            modalOkText = "Lopeta julkaisu";
        }

        Modal.confirm({
            title: modalTitle,
            content: modalContent,
            okText: modalOkText,
            cancelText: "Peruuta",
            onOk: async () => {
                setIsSaving(true);

                setAuction((prevAuction) => ({
                    ...prevAuction,
                    status: status,
                }));

                if (status === "published") {
                    try {
                        setIsSaving(false);

                        if (response.price !== "0.00") {
                            // Directly navigate since price is non-zero
                            navigate(`/maksu/${uuid}` + (auction.discountCode ? "/" + auction.discountCode : ""));
                        } else {
                            Modal.okText = (
                                <>
                                    <Spin /> Julkaistaan...
                                </>
                            );

                            setAuction((prevAuction) => ({
                                ...prevAuction,
                                paid: true,
                            }));

                            await api.saveAuction(uuid, auction);
                            await api.publishAuction(uuid);
                            setPublishedModalOpen(true);
                        }
                    } catch (error) {
                        //console.log(error);
                    } finally {
                        setIsSaving(false);
                    }
                } else {
                    try {
                        await api.saveAuction(uuid, { ...auction, status: status });
                        setIsSaving(false);
                        message.success(modalSuccess);
                    } catch (error) {
                        //console.log(error);
                    } finally {
                        setIsSaving(false);
                    }
                }
            },
        });
    };

    const handlePropertySelect = (property) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            propertyId: property.id,
            property: property,
            propertyFiles: property.files,
            images: property.images,
        }));
    };

    const statusColors = ["#f9c826", "#abe5aa", "#eeeeee", "#b0b0b0", "#ff8888", "#cccccc"];
    const statusNames = ["draft", "published", "sold", "expired", "cancelled", "expired"];
    const statusLabels = {};

    statusNames.forEach((name, index) => {
        statusLabels[name] = statusColors[index];
    });

    const validateForm = (form) => {
        form.setFields(
            form.getFieldsError().map(({ name }) => ({
                name,
                errors: [],
            }))
        );

        const errors = [];
        const errorDiv = document.querySelectorAll(".formError");

        errorDiv.forEach((div) => {
            div.classList.add("hidden");
        });

        const requiredFields = [
            ["", "property", "Kohde on pakollinen"],
            ["", "propertyWeekStart", "Valitse seuraava huoneiston käyttöoikeusviikko"],
            ["", "startPrice", "Hinta on pakollinen"],
            ["auction", "minBid", "Minimikorotus on pakollinen"],
            ["auction", "minSalePrice", "Alin myyntihinta on pakollinen"],
            ["sale", "saleTime", "Myyntiaika on pakollinen"],
            ["", "terms[]", "Ilmoitusehdot on hyväksyttävä"],
        ];

        requiredFields.forEach((field) => {
            if (
                (field[0] === auction.auctionType || field[0] === "") &&
                field[1] !== "terms[]" &&
                auction[field[1]] === null
            ) {
                errors.push({
                    name: field[1],
                    error: field[2],
                });
            }

            // Check if all terms[] fields are checked
            if (field[1] === "terms[]") {
                const terms = document.querySelectorAll("input[name='terms[]']");
                let checked = 0;

                terms.forEach((term) => {
                    if (term.checked) {
                        checked++;
                        term.parentElement.parentElement.classList.remove("text-red-600");
                    } else {
                        term.parentElement.parentElement.classList.add("text-red-600");
                    }
                });

                if (checked < terms.length) {
                    errors.push({
                        name: field[1],
                        error: field[2],
                    });
                }
            }
        });

        setIsSaving(false);

        if (errors.length > 0) {
            errorDiv.forEach((div) => {
                div.innerHTML = errors.map((error) => `<div>${error.error}</div>`).join("");
                div.classList.remove("hidden");
            });

            form.setFields(errors);

            return false;
        }

        return true;
    };

    useEffect(() => {
        auction.images.forEach((image, index) => {
            image.sort_num = index + 1;
        });
    }, [auction.images]);

    const handleLinksChange = (newLinks) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            links: newLinks,
        }));
    };

    const handleMandatorChange = useCallback((mandatorData) => {
        setAuction((prevAuction) => ({
            ...prevAuction,
            mandator: mandatorData.mandator,
            hasMandator: mandatorData.isMandatorChecked,
        }));
    }, []);

    const handleBuyFeatured = async (e) => {
        if (!featuredUntil) {
            setFeaturedError("Valitse etusivun näkyvyysaika");
            return;
        }

        navigate(`/nosto/maksu/${auction.uuid}?months=${featuredUntil}`);
    };

    const handleChangeFeaturedUntil = (event) => {
        setFeaturedUntil(event.target.value);
    };

    const transferToUser = async () => {
        if (!transferToUserId) {
            message.warning("Valitse käyttäjä, jolle ilmoitus siirretään");
            return;
        }

        try {
            await api.saveAuction(uuid, auction);
            await api.transferAuctionToUser(auction.uuid, transferToUserId);
            message.success("Ilmoitus siirretty käyttäjälle");
            navigate(`/oma-sivu/ilmoitukset`);
        } catch (error) {
            message.error("Virhe ilmoituksen siirtämisessä");
        }
    };

    const sendContactRequest = async () => {
        try {
            await api.sendContactRequest(auction.uuid);
            setContactRequestSent(true);
        } catch (error) {
            message.error("Virhe yhteydenottopyynnön lähettämisessä");
        }
    };

    return (
        <div className="auction-form">
            <Spin spinning={isSaving} tip="Tallennetaan...">
                <Spin spinning={isLoading} tip="Ladataan...">
                    <h1 className="text-2xl font-bold mb-5 flex justify-between">
                        {auction.status ? "Muokkaa ilmoitusta " : "Uusi ilmoitus"}
                        {auction.number && (
                            <Link to={`/kohde/${auction.number}`} target="_blank">
                                #{auction.number}
                            </Link>
                        )}
                    </h1>

                    {auction.status && (
                        <div
                            style={{ backgroundColor: `${statusLabels[auction.status]}` }}
                            className="flex justify-between items-center p-4 mb-5 rounded"
                        >
                            <div className="text-base md:text-xl">
                                {auction.status === "draft" && <>Ilmoitus on luonnos.</>}
                                {auction.status === "published" && <>Ilmoitus on julkaistu.</>}
                                {auction.status === "sold" && <>Kohde on myyty.</>}
                                {auction.status === "cancelled" && <>Ilmoitus on poistettu.</>}
                                {auction.status === "expired" && auction.auctionType === "auction" && (
                                    <>Huutokauppa on päättynyt.</>
                                )}
                                {auction.status === "expired" && auction.auctionType !== "auction" && (
                                    <>Ilmoitus on vanhentunut.</>
                                )}
                            </div>
                            {auction.status === "draft" && (
                                <>
                                    <div
                                        className="px-4 py-2 rounded inline-block text-base md:text-lg cursor-pointer bg-emerald-500 hover:bg-emerald-400 transition-colors text-white font-bold"
                                        onClick={() => handleStatusChange("published")}
                                    >
                                        Julkaise ilmoitus
                                    </div>
                                </>
                            )}
                            {auction.status === "published" && (
                                <>
                                    <div>
                                        {/* <div
                                        className="px-4 py-2 rounded inline-block text-lg cursor-pointer bg-emerald-600 hover:bg-emerald-500 transition-colors text-white font-bold mr-5"
                                        onClick={() => setBuyFeaturedModalOpen(true)}
                                    >
                                        Osta etusivun paraatipaikka
                                    </div> */}
                                        <div
                                            className="px-4 py-2 rounded inline-block text-base md:text-lg cursor-pointer bg-primary hover:bg-primary-300 transition-colors text-white font-bold mr-5"
                                            onClick={() => handleStatusChange("sold")}
                                        >
                                            Merkitse myydyksi
                                        </div>
                                        <div
                                            className="px-4 py-2 rounded inline-block text-base md:text-lg cursor-pointer bg-red-500 hover:bg-red-700 transition-colors text-white"
                                            onClick={() => handleStatusChange("cancelled")}
                                        >
                                            Lopeta julkaisu
                                        </div>
                                    </div>
                                </>
                            )}
                            {auction.status === "cancelled" && hasRole(user.roles, "ROLE_SUPERADMIN") && (
                                <>
                                    <div
                                        className="px-4 py-2 rounded inline-block text-base md:text-lg cursor-pointer bg-yellow-500 hover:bg-emerald-400 transition-colors text-white font-bold"
                                        onClick={() => handleStatusChange("draft")}
                                    >
                                        Merkitse luonnokseksi
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    {showContactRequest && (
                        <div className="formError text-black bg-blue-200 text-base text-center px-3 py-5 mb-5 rounded">
                            {contactRequestSent ? (
                                <div>Kiitos yhteydenottopyynnöstä! Olemme sinuun yhteydessä.</div>
                            ) : (
                                <>
                                    Kaipaatko apua ilmoituksen luonnissa? Lähetä meille yhteydenottopyyntö, niin otamme
                                    sinuun yhteyttä mahdollisimman pian.
                                    <div className="mt-5">
                                        <Button color="" className="mx-2" onClick={handleDoNotShow}>
                                            Älä näytä enää tässä ilmoituksessa
                                        </Button>

                                        <Button type="primary" onClick={sendContactRequest} className="mx-2">
                                            Lähetä yhteydenottopyyntö
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    <div className="formError text-white bg-red-600 px-3 py-2 mb-5 rounded hidden"></div>

                    {/* {!auction.status && (
                        <div className="text-lg font-normal mb-2">
                            <p>Voit halutessasi tilata ilmoituksen luonnin palvelumme kautta.</p>
                            
                            <Button type="primary" onClick={() => setServiceModalOpen(true)} className="mb-5">
                                Tilaa ilmoituksen luonti
                            </Button>

                            <p>Jatka ilmoituksen luontia itse valitsemalla ilmoituksen tyyppi.</p>
                        </div>
                    )} */}

                    <div className="bg-gray-200 rounded p-5 mb-5">
                        <Form
                            layout="vertical"
                            size="medium"
                            onFinish={handleSave}
                            form={form}
                            disabled={auction.status === "sold"}
                        >
                            {auction.type !== undefined && auction.status !== "draft" && (
                                <div className="mb-5 flex items-center bg-yellow-400 rounded p-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="size-6 mr-2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                        />
                                    </svg>
                                    Ilmoitus on julkaistu ja sitä ei voi muokata
                                </div>
                            )}

                            <div className="text-xl font-bold mb-2">Ilmoituksen tyyppi</div>
                            <Form.Item label="">
                                <Radio.Group
                                    name="auctionType"
                                    value={auction.auctionType}
                                    onChange={handleSelectAuctionType}
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value="auction">Huutokauppa</Radio.Button>
                                    <Radio.Button value="sale">Myynti</Radio.Button>
                                    <Radio.Button value="rent">Vuokraus</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {auction.auctionType !== "" && (
                                <>
                                    <AuctionFormProperty auction={auction} setAuction={setAuction} />

                                    {(auction.roomNumber || auction.propertyAttributes?.apartment) && (
                                        <>
                                            <hr className="my-5 border-gray-500" />

                                            <AuctionFormSaleInfo auction={auction} setAuction={setAuction} />

                                            <hr className="my-5 border-gray-500" />

                                            <FileUpload
                                                initialFiles={auction.files}
                                                onFilesChange={handleFilesChange}
                                                auctionId={auction.uuid}
                                                disabled={auction.status === "sold"}
                                            />

                                            {auction.propertyFiles?.length > 0 && (
                                                <>
                                                    <div className="text-sm font-bold mt-4 mb-2">
                                                        Kohteen vakiotiedostot
                                                    </div>
                                                    <div className="mb-5 text-base">
                                                        {auction.propertyFiles.map((file, index) => (
                                                            <div key={index} className="flex items-center">
                                                                <DownloadOutlined className="mr-2 text-2xl" />
                                                                <Link
                                                                    key={index}
                                                                    to={URL + "/lataa/" + file.id}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="underline text-blue-900"
                                                                >
                                                                    {file.description}
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}

                                            <div className="mt-5">
                                                <AuctionFormLinks
                                                    links={auction.links}
                                                    onLinksChange={handleLinksChange}
                                                />
                                            </div>

                                            <hr className="my-5 border-gray-500" />

                                            <AuctionFormMandator
                                                initialMandator={auction.mandator}
                                                onMandatorChange={handleMandatorChange}
                                            />

                                            <hr className="my-5 border-gray-500" />

                                            <Form.Item label="Alennuskoodi" name="discountCode" className="mt-5">
                                                <Input
                                                    className="w-36"
                                                    placeholder="Alennuskoodi"
                                                    value={auction.discountCode}
                                                    onChange={(e) =>
                                                        setAuction({ ...auction, discountCode: e.target.value })
                                                    }
                                                />
                                            </Form.Item>

                                            <div className="mt-5">
                                                <h2 className="text-xl font-bold mb-2">Ilmoitusehdot</h2>
                                                <Checkbox
                                                    className="terms mb-2 font-medium"
                                                    name="terms[]"
                                                    required={true}
                                                >
                                                    Vahvistan, että ilmoituksessa olevat tiedot ovat oikeat ja että
                                                    ilmoittajana olen vastuussa kohteen tietojen oikeellisuudesta sekä
                                                    hyväksyn{" "}
                                                    <span className="text-blue-600 underline" onClick={showTermsModal}>
                                                        käyttöehdot
                                                    </span>
                                                    .
                                                </Checkbox>
                                            </div>
                                        </>
                                    )}

                                    <div className="formError text-white bg-red-600 px-3 py-2 my-5 mb-5 rounded hidden"></div>

                                    <div className="md:text-right block md:flex md:justify-end mt-5">
                                        <Button
                                            type="button"
                                            onClick={() => setPreviewModalOpen(true)}
                                            className="bg-yellow-500 text-black hover:bg-yellow-400 w-full md:w-auto block md:inline-block mb-2 md:mb-0"
                                        >
                                            Esikatselu
                                        </Button>
                                        {auction.status === "draft" && (
                                            <Button
                                                type="success"
                                                className="bg-green-400 hover:bg-green-300 md:ml-5 w-full md:w-auto block md:inline-block mb-2 md:mb-0"
                                                onClick={() => handleStatusChange("published")}
                                                disabled={auction.apartment === null}
                                            >
                                                Julkaise ilmoitus
                                            </Button>
                                        )}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={handleSave}
                                                className="md:ml-5 w-full md:w-auto block md:inline-block mb-2 md:mb-0"
                                            >
                                                Tallenna ilmoitus
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                    {hasRole(user.roles, "ROLE_SUPERADMIN") && (
                        <div className="bg-yellow-300 p-5 rounded mb-5">
                            <h2>Ylläpitäjän toiminnot</h2>

                            <h3>Siirrä ilmoitus toiselle käyttäjälle</h3>

                            <Select
                                name="transferToUserId"
                                placeholder="Valitse käyttäjä"
                                showSearch
                                popupMatchSelectWidth={false}
                                optionFilterProp="label"
                                options={users.map((user) => ({
                                    value: user.id,
                                    label: `${user.fullnameReverse} (${user.email})`,
                                }))}
                                onChange={(value) => setTransferToUserId(value)} // Update state on change
                            />

                            <Button
                                type="primary"
                                onClick={transferToUser}
                                className="md:ml-5 w-full md:w-auto block md:inline-block mb-2 md:mb-0"
                            >
                                Siirrä
                            </Button>
                        </div>
                    )}

                    <PropertySelectModal
                        open={propertyModalOpen}
                        onClose={() => setPropertyModalOpen(false)}
                        onSelectProperty={handlePropertySelect}
                    />
                    <SettingsModal
                        title="Käyttöehdot"
                        settingName="terms_of_use"
                        open={termsModalOpen}
                        onClose={closeTermsModal}
                        className="w-1/2"
                    />
                    <PreviewModal
                        title="Esikatselu"
                        open={previewModalOpen}
                        onClose={() => setPreviewModalOpen(false)}
                        auction={auction}
                    />
                    <Modal
                        open={publishedModalOpen}
                        onClose={() => setPublishedModalOpen(false)}
                        footer={
                            <>
                                <Button type="primary" onClick={() => setPublishedModalOpen(false)}>
                                    Sulje
                                </Button>
                            </>
                        }
                    >
                        <div>
                            <p className="text-2xl">Ilmoituksesi on julkaistu!</p>
                            <p>
                                <Link to={`/kohde/${auction.number}`} className="underline text-blue-600 text-xl">
                                    Katso ilmoitus täällä
                                </Link>
                            </p>
                        </div>
                    </Modal>
                    <Modal
                        open={buyFeaturedModalOpen}
                        onClose={() => setBuyFeaturedModalOpen(false)}
                        footer={
                            <ConfigProvider>
                                <div className="flex justify-between">
                                    <Button color="danger" onClick={() => setBuyFeaturedModalOpen(false)}>
                                        Sulje
                                    </Button>

                                    <Button type="primary" onClick={() => handleBuyFeatured()}>
                                        Siirry maksamaan
                                    </Button>
                                </div>
                            </ConfigProvider>
                        }
                    >
                        <div>
                            <p className="text-2xl">Osta etusivun paraatipaikka</p>
                            <p>
                                Olet ostamassa etusivun paraatipaikkaa. Valitse haluamasi aika, jonka Ilmoituksesi näkyy
                                etusivulla ja siirry maksamaan.
                            </p>

                            {featuredError && <p className="text-red-600">{featuredError}</p>}

                            <Radio.Group name="featuredUntil" onChange={handleChangeFeaturedUntil} buttonStyle="solid">
                                <Radio.Button value="1">1 kk - 49,90€</Radio.Button>
                                <Radio.Button value="2">2 kk - 79,00€</Radio.Button>
                                <Radio.Button value="3">3 kk - 99,90€</Radio.Button>
                            </Radio.Group>
                        </div>
                    </Modal>
                    <ServiceModal open={serviceModalOpen} onClose={() => setServiceModalOpen(false)} />
                </Spin>
            </Spin>
        </div>
    );
};

export default AuctionForm;
