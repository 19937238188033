import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Spin, Pagination, Tabs } from "antd";
import { HomeFilled, FolderFilled, LeftOutlined } from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";

import api from "../../services/Api";
import ImageSelectThumbnail from "../common/ImageSelectThumbnail";

const GalleryModal = ({ open, onClose, propertyId, onSelectImages, selectedImages }) => {
    const { user } = useContext(AuthContext);

    const [activeTab, setActiveTab] = useState("property");
    const [propertyImages, setPropertyImages] = useState([]);
    const [userImages, setUserImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [folders, setFolders] = useState([]);
    const [loadingImages, setLoadingImages] = useState(true);
    const [selectedImageIds, setSelectedImageIds] = useState(new Set(selectedImages?.map((img) => img.id) || []));
    const [selectedImageObjects, setSelectedImageObjects] = useState([...selectedImages]);

    // Pagination states for both tabs
    const [currentPageProperty, setCurrentPageProperty] = useState(1);
    const [currentPageGallery, setCurrentPageGallery] = useState(1);
    const [pageSize] = useState(8);
    const [totalPropertyImages, setTotalPropertyImages] = useState(0);
    const [totalGalleryImages, setTotalGalleryImages] = useState(0);

    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [parentFolderId, setParentFolderId] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState(null);
    const [folder, setFolder] = useState(null);

    const fetchImages = async () => {
        setLoading(true);
        try {
            if (activeTab === "property" && propertyId) {
                const response = await api.getPropertyImages(propertyId, currentPageProperty, pageSize);
                setPropertyImages(response.images);
                setTotalPropertyImages(response.total);
            } else if (activeTab === "gallery" && user.uuid) {
                fetchFolder(0, currentPageGallery, pageSize);
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImages();
    }, [open, activeTab, currentPageProperty, currentPageGallery]);

    useEffect(() => {
        if (activeTab === "gallery") {
            setFolder(null);
            setCurrentFolderId(null);
        }
    }, [activeTab]);

    useEffect(() => {
        fetchFolder(currentFolderId, currentPageGallery, pageSize);
    }, [currentFolderId, currentPageGallery, pageSize]);

    const fetchFolder = async (currentFolderId, page, pageSize) => {
        setLoadingImages(true);

        try {
            const response = await api.getFolder(currentFolderId, page, pageSize);
            setFolder(response.folder);
            setUserImages(response.images);
            setFolders(response.folders);
            setTotalGalleryImages(response.totalFiles);
            setParentFolderId(response.folder?.parentId);
            setBreadcrumbs(response.breadcrumbs);
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setLoadingImages(false);
        }
    };

    const handleImageSelect = (image) => {
        setSelectedImageIds((prevSelectedImageIds) => {
            const newSelectedImageIds = new Set(prevSelectedImageIds);

            if (newSelectedImageIds.has(image.id)) {
                newSelectedImageIds.delete(image.id);
                setSelectedImageObjects((prev) => prev.filter((img) => img.id !== image.id));
            } else {
                newSelectedImageIds.add(image.id);
                setSelectedImageObjects((prev) => [...prev, image]);
            }

            return newSelectedImageIds;
        });
    };

    const handleConfirmSelection = () => {
        onSelectImages(selectedImageObjects);
        onClose();
    };

    const handlePageChangeProperty = (page) => {
        setCurrentPageProperty(page);
    };

    const handlePageChangeGallery = (page) => {
        setCurrentPageGallery(page);
    };

    const handleFolderClick = (folderId) => {
        setCurrentPageGallery(1);
        setCurrentFolderId(folderId);
    };

    const items = [
        {
            key: "property",
            label: "Lomahuuto.fi -kohdekuvat (" + totalPropertyImages + ")",
            children: (
                <>
                    <div className="relative">
                        {loading && <div className="absolute w-full h-full flex justify-center items-center bg-white bg-opacity-30 z-10"><Spin /></div>}
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-1 md:gap-4 mb-3">
                            {propertyImages.map((image) => (
                                <ImageSelectThumbnail
                                    key={image.id}
                                    image={image}
                                    selected={selectedImageIds.has(image.id)}
                                    onClick={() => handleImageSelect(image)}
                                />
                            ))}
                        </div>
                        <Pagination
                            current={currentPageProperty}
                            pageSize={pageSize}
                            total={totalPropertyImages}
                            onChange={handlePageChangeProperty}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            ),
        },
        {
            key: "gallery",
            label: "Oma kuvapankki",
            children: (
                <>
                    <div className="flex flex-row justify-between mb-3">
                        <div className="flex items-center">
                            <HomeFilled onClick={() => handleFolderClick(0)} className="text-primary" />
                            {folder && breadcrumbs && breadcrumbs.length > 0 && (
                                <>
                                    <span className="mx-1">/</span>
                                    {breadcrumbs.map((breadcrumb, index) => (
                                        <div key={breadcrumb.id} className="">
                                            <span
                                                className="cursor-pointer underline hover:text-blue-600"
                                                onClick={() => handleFolderClick(breadcrumb.id)}
                                            >
                                                {breadcrumb.name}
                                            </span>
                                            <span className="mx-1">{index < breadcrumbs.length - 1 && "/"}</span>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <Pagination
                            current={currentPageGallery}
                            pageSize={pageSize}
                            total={totalGalleryImages}
                            onChange={handlePageChangeGallery}
                            showSizeChanger={false}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-1 md:grid-cols-4 md:gap-4">
                        {loadingImages ? (
                            <Spin />
                        ) : (
                            <>
                                {currentFolderId !== null && currentFolderId !== 0 && (
                                    <div
                                        className="flex flex-col items-center justify-center border p-5 cursor-pointer w-full h-32 select-none"
                                        onClick={() => handleFolderClick(parentFolderId)}
                                    >
                                        <LeftOutlined style={{ fontSize: "40px" }} />
                                    </div>
                                )}
                                {folders.map((folder) => (
                                    <div
                                        key={folder.id}
                                        className="flex flex-col items-center justify-center border p-5 cursor-pointer w-full h-32 text-center select-none"
                                        onClick={() => handleFolderClick(folder.id)}
                                    >
                                        <FolderFilled className="text-4xl text-primary" />
                                        {folder.name}
                                    </div>
                                ))}
                                {userImages.map((image) => (
                                    <ImageSelectThumbnail
                                        key={image.id}
                                        image={image}
                                        selected={selectedImageIds.has(image.id)}
                                        onClick={() => handleImageSelect(image)}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </>
            ),
        },
    ];

    return (
        <Modal
            className="z-50"
            title="Valitse kuvat"
            open={open}
            onCancel={onClose}
            width={800}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Peruuta
                </Button>,
                <Button key="confirm" type="primary" onClick={handleConfirmSelection}>
                    Vahvista
                </Button>,
            ]}
        >
            <Tabs activeKey={activeTab} onChange={setActiveTab} items={items} size="small" defaultActiveKey="1" />
        </Modal>
    );
};

export default GalleryModal;
